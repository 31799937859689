import { ITEM_TYPES } from '@wix/advanced-seo-utils';
import { type BlogTag } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/types';
import type { IWixWindow, SeoTagsPayload } from '@wix/native-components-infra/dist/src/types/types';
import {
  getFullLanguageCode,
  getLastPage,
  getMultilingualQueryParam,
  SECTION_CATEGORY,
} from '@wix/communities-blog-client-common';
import { getEntityCount, getPaginationPageSize } from '../../selectors/pagination-selectors';
import { type AppState, type NormalizedPost } from '../../types';
import { getTagListItems } from '../get-tag-list-items';
import { getTagUrl } from '../get-tag-url';
import { createTagPageUrl } from '../navigation';
import { getPaginationUrls } from './get-pagination-item-data';

type GenerateTagSEOTagsParams = {
  sectionUrl: string;
  postPageSectionUrl: string;
  tag: BlogTag;
  state: AppState;
  page: number;
  posts: NormalizedPost[];
  multilingual?: IWixWindow['multilingual'];
};

export const generateTagSEOTags = ({
  sectionUrl,
  postPageSectionUrl,
  tag,
  state,
  page,
  posts,
  multilingual,
}: GenerateTagSEOTagsParams): SeoTagsPayload => {
  const tagUrl = getTagUrl(sectionUrl, tag.slug);

  const pageSize = getPaginationPageSize(state, SECTION_CATEGORY);
  const entityCount = getEntityCount(state, 'posts');
  const lastPage = getLastPage(entityCount, pageSize);

  const multilingualQueryParam = multilingual ? getMultilingualQueryParam(multilingual) : '';

  const pagination = {
    ...getPaginationUrls(tagUrl, page, lastPage, state, multilingualQueryParam),
    totalPages: parseInt(Math.ceil(entityCount / pageSize) as unknown as string, 10),
    currentPage: page,
  };

  const itemData = {
    name: tag.label,
    title: tag.label,
    fullUrl: createTagPageUrl(tag, page, multilingualQueryParam),
    listItems: getTagListItems(posts, postPageSectionUrl, multilingual),
    items: {
      numberOfItems: posts.length,
    },
    created: tag.createdDate,
    postCount: tag.postCount,
    language: getFullLanguageCode(multilingual?.siteLanguages, tag.language ?? undefined),
    pagination,
  };

  return {
    itemType: ITEM_TYPES.BLOG_TAGS,
    itemData: { tag: itemData },
    asNewPage: true,
    seoData: tag.seoData,
  };
};
