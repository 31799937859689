import {
  createMembersAreaWidgetPluginService,
  createWidgetPluginExports,
} from '@wix/members-area-widget-plugin-lib/viewer';
import type { IWidgetController } from '@wix/native-components-infra/dist/src/types/types';
import { createPerformanceTracker } from '@wix/communities-blog-client-common';
import { type WithReduxStoreOwnProps } from '../../common/components/runtime-context/with-redux-store';
import { createControllerId, createLogger } from '../../common/controller/helpers';
import { initLazyActions } from '../../common/controller/lazy-actions';
import { getPlatformApi } from '../../common/controller/platform-api';
import { createMonitoring } from '../../common/services/create-monitoring';
import { getCurrentSiteLanguage } from '../../common/services/get-current-site-language';
import getEnvironment from '../../common/services/get-environment';
import { isRtlLanguage } from '../../common/services/is-rtl-language';
import listenToInstanceChange from '../../common/services/listen-to-instance-change';
import { simulateControllerError } from '../../common/services/simulate-error';
import { subscribeToChange } from '../../common/services/state-optimizer';
import { getInitialStateVersions } from '../../common/services/state-optimizer/change-detector';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { type ControllerConfig, type FlowAPI } from '../../common/types';
import { MY_POSTS_PAGE_BUNDLE_NAME } from '../constants/my-posts-page';
import { createReduxStore } from './create-redux-store';
import {
  initializeActions,
  initializePromisifiedActions,
  initializeStoreBaseData,
  refreshDataOnLogin,
} from './init-actions';

type RootProps = WithReduxStoreOwnProps & {
  isRTL: boolean;
  fitToContentHeight: boolean;
  isRendered: boolean;
};

export const createMyPostsPageController = async (
  controllerConfig: ControllerConfig,
  flowAPI: FlowAPI,
): Promise<IWidgetController> => {
  const { appParams, compId, config, setProps, platformAPIs, platformApiProvider } =
    controllerConfig;

  const platformApi = await getPlatformApi(platformApiProvider);
  const { isSSR, isDebug, isProduction } = getEnvironment(platformApi);
  const { fedopsLogger } = createMonitoring(flowAPI);
  const language = getCurrentSiteLanguage(platformApi, flowAPI);
  const bundleName = MY_POSTS_PAGE_BUNDLE_NAME;
  const log = createLogger(isDebug, isProduction);

  log('createMyPostsPageController', {
    appParams,
    allCtrls: undefined,
    platformApi,
    isSSR,
    language,
  });

  const widgetPluginService = createMembersAreaWidgetPluginService();

  let store: any;

  const pageReady = async () => {
    const controllerId = createControllerId();
    const perfTag = `${bundleName} ${controllerId}`;
    const perf = createPerformanceTracker(perfTag, { isDebug, isSSR });
    const pageReadyMarker = perf.trackStart(`${new Date().toISOString().slice(11)} pageReady`);
    log('createMyPostsPageController.pageReady -> start');

    simulateControllerError(platformApi, 'my-posts-page.pageReady');

    initLazyActions({ isSSR });

    let marker = perf.trackStart('createReduxStore', pageReadyMarker);
    store = createReduxStore({
      appParams,
      platformApi,
      compId,
      fedopsLogger,
      isSSR,
      language,
      bundleName: perfTag,
      flowAPI,
    });
    perf.trackEnd(marker);

    marker = perf.trackStart('initializeActions', pageReadyMarker);
    const actions = initializeActions({
      platformApi,
      store,
      fedopsLogger,
    });
    const actionsPromisified = initializePromisifiedActions({ store });
    perf.trackEnd(marker);

    await perf.trackPromise(
      'initializeStoreBaseData',
      () =>
        initializeStoreBaseData({
          platformApi,
          store,
          language,
          platformAPIs,
          config,
          bundleName,
          appParams,
          isRendered: createMembersAreaWidgetPluginService().getIsRendered(),
          widgetPluginService,
        }),
      pageReadyMarker,
    );
    listenToInstanceChange(platformApi, appParams, store);

    log('createMyPostsPageController.pageReady -> done');
    const state = store.getState();

    const stateVersions = getInitialStateVersions(state);

    setProps({
      state,
      stateVersions,
      actions,
      actionsPromisified,
      isSSR,
      isRTL: isRtlLanguage(language),
      controllerId,
      fitToContentHeight: true,
      isRendered: true,
    } satisfies RootProps);

    refreshDataOnLogin({ platformApi, store, router: undefined });
    subscribeToChange(store, stateVersions, setProps, controllerId);
    perf.trackEnd(pageReadyMarker);
  };

  return Promise.resolve({
    pageReady: () => {
      const isRendered = widgetPluginService.getIsRendered();

      if (!isRendered) {
        controllerConfig.setProps({ isRendered } satisfies Partial<RootProps>);
        return;
      }

      return pageReady();
    },
    exports: () => createWidgetPluginExports(widgetPluginService, pageReady),
    updateConfig: (_$w, { style: { styleParams } }) => {
      if (!store) {
        return;
      }
      store.dispatch(setAppSettings({ style: styleParams }));
    },
  });
};
