import { setProfileUrls } from './profile-urls-actions';

type ProfileUrlsState = { [postId: string]: string };

type AnyAction = ReturnType<typeof setProfileUrls>;

const initialState: ProfileUrlsState = {};

export default (state = initialState, action: AnyAction): ProfileUrlsState => {
  switch (action.type) {
    case setProfileUrls.type:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
