import type React from 'react';
import type { CreateControllerFn, ControllerParams, WidgetProps } from '@wix/yoshi-flow-editor';
import { joinUrlPaths } from '@wix/communities-blog-client-common';
import { BLOG_FEED_PATH } from '@wix/communities-blog-universal/dist/src/constants/app';
import { createLogger } from '../../external/common/controller/helpers';
import { getPlatformApi } from '../../external/common/controller/platform-api';
import getEnvironment from '../../external/common/services/get-environment';
import type Widget from './Widget';

const createController: CreateControllerFn = async ({ controllerConfig }: ControllerParams) => {
  const platformApi = await getPlatformApi(controllerConfig.platformApiProvider);
  const { isDebug, isProduction } = getEnvironment(platformApi);
  const log = createLogger(isDebug, isProduction);
  log('createRssButtonController', controllerConfig);

  return {
    pageReady() {
      log('createRssButtonController.pageReady -> start');

      setProps({
        rssFeedLink: getRssFeedLink(),
      });

      log('createRssButtonController.pageReady -> done');
    },
  };

  function getRssFeedLink() {
    /** "" if site is not published */
    const baseUrl = platformApi.location.baseUrl;

    return baseUrl ? joinUrlPaths(baseUrl, [BLOG_FEED_PATH]) : undefined;
  }

  /** Type-safe wrapper */
  function setProps(props: Omit<React.ComponentProps<typeof Widget>, keyof WidgetProps<{}>>) {
    controllerConfig.setProps(props);
  }
};

export default createController;
