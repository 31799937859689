import { type BlogTag } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/types';
import {
  getPostPageSectionUrl,
  getTagBySlug,
  SECTION_CATEGORY,
} from '@wix/communities-blog-client-common';
import { pageOpened } from '../../../common/actions/page-opened';
import type { PlatformApi } from '../../../common/controller/platform-api';
import { isPostContentRequired } from '../../../common/selectors/layout-selectors';
import {
  getIsValidPage,
  getLazyPaginationParams,
  getShowPagination,
} from '../../../common/selectors/pagination-selectors';
import { getFeedPosts, getSortedPostsByTagId } from '../../../common/selectors/post-selectors';
import { TAGS_PAGE } from '../../../common/services/detect-route';
import { generateTagSEOTags } from '../../../common/services/generate-seo-tags/generate-tag-seo-tags';
import {
  getBasicParams,
  isSite,
  isSSR,
} from '../../../common/store/basic-params/basic-params-selectors';
import { fetchProfileUrls } from '../../../common/store/profile-urls/profile-urls-actions';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import { type AppStore, type RouteResolverFn } from '../../../common/types';
import { fetchTagFeedRenderModel } from '../../actions/fetch-feed-render-model';
import { ROUTE_404, type RouteTagsParams } from '../../constants/routes';

export const createTagPageRouter = (
  store: AppStore,
  platformApi: PlatformApi,
): RouteResolverFn<RouteTagsParams> => {
  return async ({ params }, redirect, { preFetch, preFetchResult }) => {
    const page = parseInt(params.page || '1', 10);
    const tagSlug = params.tagSlug;
    const state = store.getState();
    const { language } = getBasicParams(state);

    const includeInitialPageData =
      getFeedPosts(state).length === 0 || getTagBySlug(store.getState(), tagSlug) === undefined;

    const actions = preFetchResult || [
      store.dispatch(
        fetchTagFeedRenderModel({
          page,
          tagOptions: { id: tagSlug },
          pageSize: getLazyPaginationParams({
            state,
            section: SECTION_CATEGORY,
            page,
          })?.size,
          includeContent: isPostContentRequired(state, SECTION_CATEGORY),
          includeInitialPageData,
          language,
        }),
      ),
    ];

    if (preFetch) {
      return Promise.all(actions);
    }

    if (preFetchResult) {
      await preFetchResult;
      store.dispatch(fetchProfileUrls());
    } else {
      await Promise.all(actions);
    }

    const tag = getTagBySlug(store.getState(), tagSlug);

    if (isInvalidPage() || !tag) {
      return redirect(ROUTE_404);
    }

    addSeoTags(page, tag);

    trackPageOpen();
  };

  function isInvalidPage() {
    const state = store.getState();
    const showPagination = getShowPagination(state, SECTION_CATEGORY);
    const isValidPage = getIsValidPage(state, SECTION_CATEGORY);
    return showPagination && !isValidPage;
  }

  function addSeoTags(page: number, tag: BlogTag) {
    const state = store.getState();

    if (!isSite(state) || !tag) {
      return;
    }

    const tagPosts = getSortedPostsByTagId(store.getState(), tag.id);

    const seoTags = generateTagSEOTags({
      sectionUrl: getSectionUrl(state),
      postPageSectionUrl: getPostPageSectionUrl(state),
      tag,
      state,
      page,
      posts: tagPosts,
      multilingual: platformApi.window.multilingual,
    });

    platformApi.seo.renderSEOTags(seoTags);
  }

  function trackPageOpen() {
    const state = store.getState();

    if (isSSR(state)) {
      return;
    }

    store.dispatch(pageOpened({ page: TAGS_PAGE }));
  }
};
