import { POST_PAGE_MORE_BUTTON_OPTION_CLICKED } from '../../actions/post-page-more-button-option-clicked';
import { getQueryLocale } from '../../selectors/locale-selectors';
import { getPostByIdOrSlug } from '../../selectors/post-selectors';

const handler = ({ payload }, state) => {
  const post = getPostByIdOrSlug(state, payload.postId);
  const language = getQueryLocale(state);

  return {
    evid: 241,
    language,
    action: payload.action,
    translation_id: post.translationId,
    post_stable_id: post.id,
    is_demo: post.isDemo,
    eventMeta: {
      description: 'Triggered when the user clicks on the 3 dots menu option, on the post page',
    },
  };
};

export const uouPostPageMoreButtonOptionClickedEvent = {
  [POST_PAGE_MORE_BUTTON_OPTION_CLICKED]: handler,
};
