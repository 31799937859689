import { omit } from 'lodash';
import { OPEN_MODAL, CLOSE_MODAL } from '../modal-actions';

export function openedModals(state = {}, action) {
  switch (action.type) {
    case OPEN_MODAL: {
      const { type } = action.payload;
      if (state[type]) {
        return state;
      }
      return {
        ...state,
        [type]: { ...action.payload, meta: action.meta || {} },
      };
    }
    case CLOSE_MODAL: {
      return omit(state, action.payload.type);
    }
    default:
      return state;
  }
}
