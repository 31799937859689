import { type PlatformApi } from '@app/external/common/controller/platform-api';
import { type FlowAPI } from '@app/external/common/types';
import { getPreviewInstance } from '../../common/controller/helpers';
import createRequest from '../../common/services/create-request';
import { getHttpClientFromFlowAPI } from '../../common/services/get-http-client-from-flow-api';
import postService from '../../common/services/post';
import { encodeURIComponentIfNeeded } from '../../common/services/uri';

/**
 * @class PostPage
 * @summary A post page for a specific blog post.
 * @mixes $w.Element
 * @mixes $w.HiddenCollapsedMixin
 * @memberof $w
 * @viewername wysiwyg.viewer.components.tpapps.TPAWidget
 * @definitionId 211b5287-14e2-4690-bb71-525908938c81
 * @tagname postPage
 */

/**
 * @typedef {external:Object} $w.PostPage~BlogPost
 * @summary An object representing a blog post on a post page.
 * > **Note:**
 * `viewCount` and `likeCount` data used on your site pages is eventually consistent and may require up
 * to 24 hours to update. To make sure new post views and likes are included on your site in real time,
 * [disable caching](https://support.wix.com/en/article/caching-your-sites-pages) for
 * any page that uses `viewCount` or `likeCount` data. Note that disabling caching may increase your page loading time.
 * @property {external:String} id Post ID.
 * @property {external:String} title Post title.
 * @property {external:String} plainContent Text of the post.
 * @property {external:String} author Post author ID.
 * @property {external:String} language Language of the post.
 * @property {external:String} slug Unique slug identifier of the post which is usually used in the URL.
 * @property {external:String} translationId ID of the translated post if the post is translated into other language.
 * @property {external:Date} publishedDate Date the post was originally published.
 * @property {external:Number} viewCount Number of times the post was viewed.
 * @property {external:Number} likeCount Number of likes the post received.
 * @property {external:Number} commentCount Number of comments the post received.
 * @property {external:Date} lastPublishedDate Date the post was most recently published.
 * @property {external:Boolean} coverImageDisplayed Indicates whether the cover image is displayed in the post.
 * @property {external:Number} timeToRead Estimated time in minutes required to read the post.
 * @property {external:Boolean} pinned Indicates whether the post was pinned to the top of the blog feed.
 * @property {external:Boolean} featured Indicates whether the post is set as featured in the post settings. Featured posts appear in custom blog feeds.
 * @property {external:String[]} relatedPosts List of all related posts.
 * @property {external:String[]} paidPlans List of all paid plans assigned to the post.
 * @property {external:String[]} hashtags List of all hashtags in the post.
 * @property {external:String[]} tags List of all tags assigned to the post.
 * @property {external:String[]} categories List of all categories assigned to the post.
 * @property {external:String} mainCategory Main category of the post.
 * @property {external:String} coverImage The post's cover [image]($w.Image.html#src).
 * @property {external:String} postPageUrl Relative URL of the post page on your published site.
 * @property {external:String} excerpt A few lines of text that appear in the blog feed. Defined
 *  in [Post Settings](https://support.wix.com/en/article/editing-excerpts-in-the-new-wix-blog) or default of first 160 characters of the post.
 * @snippet [PostPage-getPost.es6=Get the current blog post's information]
 * @see [`getPost()`](#getPost)
 */

export const getPath = (platformApi: PlatformApi) => {
  const path = platformApi.location.path;
  const pathParts =
    platformApi.location.baseUrl.split('.').pop()?.split('/').splice(1).filter(Boolean) ?? [];

  if (pathParts.length && pathParts.every((v, i) => v === path[i])) {
    return path.slice(pathParts.length);
  }

  return path;
};

const getSlug = async (platformApi: PlatformApi) => {
  let path = getPath(platformApi);
  const url = `/${path.map(encodeURIComponentIfNeeded).join('/')}`;
  const { segments } = (await platformApi.site.getCustomizedUrlSegments?.(url)) || {};

  if (!segments && path.length < 2) {
    return {};
  }
  let isId = false;
  if (path.length > 2 && path[path.length - 1] === 'edit') {
    path = path.slice(0, -1);
    isId = true;
  } else if (path.length > 2 && getPreviewInstance(platformApi.location)) {
    path = [];
  }

  const slug = segments
    ? encodeURIComponentIfNeeded(segments.slug)
    : path.slice(1).map(decodeURIComponent).join('/');

  return { isId, slug };
};

/**
 * @function getPost
 * @syntax
 * function getPost(): Promise<BlogPost>
 * @summary Gets the all the information associated with the current blog post.
 * @description
 *  The `getPost()` function returns a Promise that is resolved when all
 *  of the information about the post shown on the `PostPage` is
 *  retrieved.
 * @returns {Promise}
 * @fulfill {$w.PostPage~BlogPost} When the information associated with the current post has been retrieved.
 * @snippet [PostPage-getPost.es6=Get the current blog post's information]
 * @memberof $w.PostPage
 */

export const createPostPageApi = (
  instance: string,
  platformApi: PlatformApi,
  flowAPI: FlowAPI,
) => ({
  getPost: async () => {
    const { isId, slug } = await getSlug(platformApi);
    const request = createRequest({
      httpClient: getHttpClientFromFlowAPI(flowAPI),
    });
    if (!slug) {
      return postService.getPlatformizedLastPost({
        instance,
        platformApi,
        request,
      });
    }
    if (isId) {
      return postService.getPlatformizedPostById({
        instance,
        id: slug,
        platformApi,
        request,
      });
    }
    return postService.getPlatformizedPostBySlug({
      instance,
      slug,
      platformApi,
      request,
    });
  },
});
