import {
  ABOVE_CONTENT_1,
  ABOVE_CONTENT_2,
  ABOVE_HEADER,
  BELOW_CONTENT_1,
  BELOW_CONTENT_2,
  PAGE_BOTTOM_1,
  PAGE_BOTTOM_2,
  PAGE_BOTTOM_3,
} from '../../../../constants/ooi-slots';

const slots = [
  ABOVE_HEADER,
  ABOVE_CONTENT_1,
  ABOVE_CONTENT_2,
  BELOW_CONTENT_1,
  BELOW_CONTENT_2,
  PAGE_BOTTOM_1,
  PAGE_BOTTOM_2,
  PAGE_BOTTOM_3,
] as const;

export const createSetPostIdToSlotsFunction = (slotAPIFactory: Record<string, any>) => {
  return (postId: string | undefined) => {
    if (!postId) {
      return;
    }
    for (const slotId of slots) {
      const slot = slotAPIFactory.getSlotAPI(slotId);
      slot.postId = postId;
    }
  };
};
