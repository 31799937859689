import { createAction } from '@wix/communities-blog-client-common';
import { createPromisifiedAction } from '../actions-promisifier/create-promisified-action';

export const submitPostForModerationSuccess = createAction(
  'post/SUBMIT_POST_FOR_MODERATION_SUCCESS',
  (payload, meta) => payload,
  (payload, meta) => meta,
);
const submitPostForModerationFailure = createAction('post/SUBMIT_POST_FOR_MODERATION_FAILURE');

const submitPostForModeration =
  (postId) =>
  (dispatch, getState, { request }) => {
    const promise = request.post(`/v3/moderation/${postId}`);

    return promise
      .then(({ draftPost }) => dispatch(submitPostForModerationSuccess({ draftPost })))
      .catch((error) => dispatch(submitPostForModerationFailure(error)))
      .then(() => promise);
  };

export const submitPostForModerationPromisified = createPromisifiedAction(
  submitPostForModeration,
  (data) => data,
  (response) => response.status,
);
