export const SHOW = 'loading-bar/SHOW';
export const HIDE = 'loading-bar/HIDE';
export const RESET = 'loading-bar/RESET';

export const DEFAULT_SCOPE = 'default';

export function showLoading(scope = DEFAULT_SCOPE) {
  return {
    type: SHOW,
    payload: {
      scope,
    },
  };
}

export function hideLoading(scope = DEFAULT_SCOPE) {
  return {
    type: HIDE,
    payload: {
      scope,
    },
  };
}
