import { SET_ENABLE_LINKIFY } from './editor-config-actions';

export default function editorConfig(state = null, { type, payload }) {
  switch (type) {
    case SET_ENABLE_LINKIFY:
      return { ...state, enableLinkify: payload };

    default:
      return state;
  }
}
