import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'Bottom Of Page 2',
  props: {},
  methods: {
    isCommentsPluginInstalled: {
      description: 'Returns boolean value if comments plugin is installed',
    },
    getSettings: {
      description: 'Returns comments settings',
    },
  },
  events: {
    widgetLoaded: {
      description: 'Fired when Widget loaded',
    },
    commentsSettingsChanged: {
      description: 'Fired when comments settings changed',
    },
  },
});
