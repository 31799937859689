import { SET_COMMUNITIES_CONTEXT } from './communities-context-actions';

export function communitiesContext(state = {}, action) {
  switch (action.type) {
    case SET_COMMUNITIES_CONTEXT:
      return action.payload;
    default:
      return state;
  }
}
