import {
  SANTA_MEMBERS_APP_ID,
  BLOG_APP_ID,
} from '@wix/communities-universal/dist/src/constants/appsConfig';
import { isMemberAreaInstalled } from '../../../../common/members';
import { type FeedPageThunkAction } from '../../../feed-page/types';
import { MY_POSTS_WIDGET_ID } from '../../constants/widgets';
import { type PlatformApi } from '../../controller/platform-api';

export const SET_COMMUNITIES_CONTEXT = 'communitiesContext/SET';

const isMyPostsInstalled = (platformApi: PlatformApi) =>
  platformApi.site.isAppSectionInstalled({
    appDefinitionId: BLOG_APP_ID,
    sectionId: 'My Posts',
  });

export const setCommunitiesContext =
  (): FeedPageThunkAction =>
  async (dispatch, getState, { platformApi }) => {
    const [memberAreaInstalled, myPostsInstalled] = await Promise.all([
      isMemberAreaInstalled(platformApi),
      isMyPostsInstalled(platformApi),
    ]);

    dispatch({
      type: SET_COMMUNITIES_CONTEXT,
      payload: {
        [SANTA_MEMBERS_APP_ID]: {
          isInstalled: memberAreaInstalled,
        },
        [MY_POSTS_WIDGET_ID]: {
          isInstalled: myPostsInstalled,
        },
      },
    });
  };
