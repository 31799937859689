import type { AppData } from '../../viewer.app';
import blocksModel from './model';

const selectors = {
  root: '#headerRoot',
  counters: '#postCountersNew1',
  categories: '#categories1',
  tags: '#tags1',
  metadata: '#metadata1',
  ratings: '#ratings1',
  heroImage: '#image',
  title: '#title1',
} as const;

export default blocksModel.createController(({ $w, appData: _appData, $widget, flowAPI }) => {
  const appData = _appData as AppData;

  let components: ReturnType<typeof getComponents>;
  const getComponents = () => ({
    root: $w(selectors.root),
    counters: $w(selectors.counters),
    categories: $w(selectors.categories),
    tags: $w(selectors.tags),
    metadata: $w(selectors.metadata),
    ratings: $w(selectors.ratings),
    heroImage: $w(selectors.heroImage),
    title: $w(selectors.title),
  });

  return {
    pageReady: async () => {
      components = getComponents();
      await appData.subjects.postPageRenderModel.subscribe((model) => {
        if (model?.heroImageUrl) {
          components.heroImage.src = model.heroImageUrl;
        }

        components.categories.initialize(model);
        components.title.initialize(model);
        components.ratings.initialize(model);
        components.metadata.initialize(model);
        components.counters.initialize(model);
        components.tags.initialize(model);
      });

      components.categories.hostWidget = 'header';

      const isCommentsEnabled = $widget.props.isCommentsPluginInstalled;
      const isRatingsEnabled = $widget.props.isRatingsEnabled;

      const shouldShowRatingsElement = isCommentsEnabled && isRatingsEnabled;

      if (shouldShowRatingsElement) {
        // @ts-expect-error
        components.ratings.restore();
      } else {
        // @ts-expect-error
        components.ratings.delete();
      }

      if (flowAPI.environment.isEditor) {
        $widget.onPropsChanged((_, newProps) => {
          if (newProps.isRatingsEnabled) {
            // @ts-expect-error
            components.ratings.restore();
          } else {
            // @ts-expect-error
            components.ratings.delete();
          }
        });
      }
    },
    exports: {
      counters: () => $w(selectors.counters),
    },
  };
});
