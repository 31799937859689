import { BI_POST_SCROLLED } from '../actions/bi';

const handler = ({ payload }) => {
  return {
    evid: 2101,
    eventMeta: {
      description: 'post reading time',
    },
    ...payload,
  };
};

export const uouPostReadingTimeEvent = { [BI_POST_SCROLLED]: handler };
