import { type IPlatformAPI } from '@wix/yoshi-flow-editor';

export type LikeStatusChangeEvent = {
  isLiked: boolean;
  likeCount: number;
};

type SubjectMap = {
  likeStatusChanged: LikeStatusChangeEvent;
};

export function makeSubjectsForAppData(platformApis: IPlatformAPI) {
  function makeSubject<S extends keyof SubjectMap>(subject: S) {
    return {
      next: (payload: SubjectMap[S]) => platformApis.pubSub.publish(subject, payload, true),
      subscribe: (
        callback: (next: { data: SubjectMap[S]; name: typeof subject; origin: 'worker' }) => void,
      ) => {
        const id = platformApis.pubSub.subscribe(subject, callback, true);

        return {
          unsubscribe: () => platformApis.pubSub.unsubscribe(subject, id),
        };
      },
    };
  }

  return {
    likeStatusChanged: makeSubject('likeStatusChanged'),
  };
}
