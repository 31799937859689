import model from './model';

const selectors = {
  root: '#socialRoot',
  shareButtons: '#shareButtons',
} as const;

export default model.createController(({ $w }) => {
  return {
    pageReady: async () => {
      // @ts-expect-error
      $w(selectors.shareButtons).pageToShare = 'current';
    },
    exports: {},
  };
});
