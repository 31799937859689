import { SET_IS_SAVING } from './is-saving-actions';

const isSaving = (state = false, { type, payload }) => {
  switch (type) {
    case SET_IS_SAVING:
      return payload;
    default:
      return state;
  }
};

export default isSaving;
