import { type Dispatch } from 'redux';
import { type HttpClient } from '@wix/yoshi-flow-editor';
import { listCategories } from '@wix/ambassador-blog-v3-category/http';
import { Field } from '@wix/ambassador-blog-v3-category/types';
import { createAction, normalizeCategory } from '@wix/communities-blog-client-common';
import { getQueryLocale } from '../../selectors/locale-selectors';
import { type GetState } from '../../types';

export const FETCH_CATEGORIES_SUCCESS = 'categories/FETCH_SUCCESS';

const fetchCategoriesRequest = createAction('categories/FETCH_REQUEST');
export const fetchCategoriesSuccess = createAction(FETCH_CATEGORIES_SUCCESS);
const fetchCategoriesFailure = createAction('categories/FETCH_FAILURE');

const fetchCategoriesAction =
  () =>
  async (_dispatch: Dispatch, getState: GetState, { httpClient }: { httpClient: HttpClient }) => {
    const state = getState();
    const lang = getQueryLocale(state);

    const response = await httpClient.request(
      listCategories({
        paging: { limit: 100, offset: 0 },
        fieldsets: [Field.TRANSLATIONS, Field.SEO],
        language: lang,
      }),
    );

    return response.data.categories?.map(normalizeCategory);
  };

const createFetchCategories =
  (action: any) =>
  (...args: any) =>
  (dispatch: Dispatch) => {
    dispatch(fetchCategoriesRequest());

    return dispatch(action(...args))
      .then((categories: any) => dispatch(fetchCategoriesSuccess(categories)))
      .catch(() => dispatch(fetchCategoriesFailure()));
  };

const fetchCategories = createFetchCategories(fetchCategoriesAction);

export default fetchCategories;
