import { viewPost } from '@wix/ambassador-blog-v3-post/http';
import { postPageOpened } from '@wix/bi-logger-blog-app-uou/v2';
import { resolveId } from '@wix/communities-blog-client-common';
import {
  TranslatedPostRedirectError,
  BlocksWidgetFacade,
} from '../../external/blocks-widget/blocks-widget.facade';
import {
  categoryIds,
  isPublished,
  paidPlanIds,
  paidPlansHasAccess,
} from '../../external/common/bi-events/event-helpers';
import { type NormalizedPost } from '../../external/common/types';
import { type AppData } from '../../viewer.app';
import blocksModel from './model';
import { POST_PAGE_SELECTORS } from './post-page-constants';

export default blocksModel.createController(({ appData: _appData, flowAPI, $w }) => {
  const appData = _appData as AppData;
  const facade = new BlocksWidgetFacade(flowAPI, appData.platformApi);

  let components: ReturnType<typeof getComponents>;

  const getComponents = () => ({
    root: $w(`#${POST_PAGE_SELECTORS.POST_PAGE_ROOT}`),
    seeMorePostsButton: $w(`#${POST_PAGE_SELECTORS.SEE_MORE_POSTS_BUTTON}`),
    multiStateBox: $w(`#${POST_PAGE_SELECTORS.POST_PAGE_MULTISTATE_BOX}`),
    comments: $w(`#${POST_PAGE_SELECTORS.COMMENTS}`),
    postHeader: $w(`#${POST_PAGE_SELECTORS.POST_HEADER}`),
    postFooter: $w(`#${POST_PAGE_SELECTORS.POST_FOOTER}`),
  });

  const handleCommentsEvents = () => {
    if ('onCommentsSettingsChanged' in components.comments) {
      components.comments.onCommentsSettingsChanged((event: any) => {
        const data = event.data;
        if (components.postHeader && 'isRatingsEnabled' in components.postHeader) {
          components.postHeader.isRatingsEnabled = data.ratings.isEnabled;
        }
      });
    }
  };

  return {
    pageReady: async () => {
      const feedPageUrl = await facade.getUrlToFeedPage();

      components = getComponents();

      if (components.root.rendered) {
        // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
        // @ts-ignore https://dev.wix.com/docs/velo/api-reference/$w/custom-class-list/introduction
        components.root?.customClassList.add('post-page-root');
      }

      try {
        await appData.subjects.postPageRenderModel.subscribe(
          async ({ post, readingSessionId, seoTags }) => {
            if (post.preview && flowAPI.environment.isViewer) {
              // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
              // @ts-ignore
              components.comments.delete();
            } else {
              const isCommentsPluginInstalled =
                components.comments && components.comments.isCommentsPluginInstalled();

              if (isCommentsPluginInstalled) {
                handleCommentsEvents();

                const commentsSettings = await components.comments.getSettings();

                const countersInHeader = components.postHeader.counters();
                const countersInFooter = components.postFooter.counters();

                countersInHeader.isCommentsPluginInstalled = isCommentsPluginInstalled;
                countersInFooter.isCommentsPluginInstalled = isCommentsPluginInstalled;

                components.postHeader.isRatingsEnabled =
                  commentsSettings?.ratings?.isEnabled ?? false;

                components.postHeader.isCommentsPluginInstalled = isCommentsPluginInstalled;
              }
            }

            facade.assertPostIsLoadedInCorrectLanguage(post);

            flowAPI.bi?.report(
              postPageOpened({
                reading_session_id: readingSessionId,
                referrer: (appData.platformApi.window as { referrer?: string }).referrer,
                post_stable_id: post.id,
                paid_plan_has_access: paidPlansHasAccess(post) || undefined,
                paid_plan_id: paidPlanIds(post),
                category_ids: categoryIds(post),
                is_published: Boolean(isPublished(post)),
              }),
            );

            const shouldTriggerPostView =
              flowAPI.environment.isViewer &&
              !flowAPI.environment.isSEO &&
              !flowAPI.environment.isSSR;

            if (shouldTriggerPostView) {
              flowAPI.httpClient.request(viewPost({ postId: post.id! })).catch(() => {}); // Ignore if rate-limited
            }

            appData.platformApi.seo.renderSEOTags(seoTags);
          },
        );
      } catch (error) {
        if (!flowAPI.environment.isViewer || error instanceof TranslatedPostRedirectError) {
          return;
        }

        appData.platformApi.seo.setSeoStatusCode(404);
        components.multiStateBox.changeState(POST_PAGE_SELECTORS.NOT_FOUND);
        components.seeMorePostsButton.link = feedPageUrl;
        components.seeMorePostsButton.target = '_self';
      }
    },

    exports: {
      ...blocksModel.getExports(),
      getPost: async (): Promise<{ uuid: string | undefined } & NormalizedPost> => {
        const post = await appData.fetchPublicPostPageRenderModel();
        return { uuid: resolveId(post), ...post };
      },
    },
  };
});
