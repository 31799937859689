export const selectors = {
  likesFilledNumber: '#likesFilledNumber',
  likesDefaultNumber: '#likesDefaultNumber',
  likesButtonEmpty: '#button2',
  likesButtonFilled: '#button1',
  views: '#viewsNumber',
  viewsContainer: '#viewsContainer',
  comments: '#commentsNumber',
  commentsContainer: '#commentsContainer',
} as const;
