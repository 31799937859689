import { has, get } from 'lodash';
import { MODAL_TYPE_POST_SETTINGS } from '@wix/communities-blog-client-common';
import { CLOSE_MODAL } from '../../modals/framework/store/modal-actions';
import { getPostSettingsModalProps } from '../../selectors/modal-selectors';
// import { SAVE_AND_PUBLISH_POST } from '../../actions/save-and-publish-post';

const handler = (fields, state, evid, buttonName) => {
  const { formInitialValues, isPublished } = getPostSettingsModalProps(state);
  const hasChanged = (key) => has(fields, key) && get(formInitialValues, key) !== get(fields, key);

  return {
    evid,
    eventMeta: {
      isNavigation: false,
      description: 'Advanced post settings Done click',
    },
    categories_chosen: hasChanged('categoryIds')
      ? get(fields, 'categoryIds.length') - get(formInitialValues, 'categoryIds.length', 0)
      : 0,
    is_author_changed: hasChanged('ownerSiteMemberId'),
    is_changed_excerpt: hasChanged('excerpt'),
    is_changed_search_engine_title: hasChanged('seoTitle'),
    is_changed_url: hasChanged('seoSlug'),
    is_cover_image_changed: hasChanged('coverImage.src'),
    is_on_display_cover_toggle: hasChanged('coverImage.isEnabled'),
    is_on_feature_post_toggle: hasChanged('isFeatured'),
    is_publish_date_edited: hasChanged('firstPublishedDate'),
    is_written_description: hasChanged('seoDescription'),
    is_published: isPublished,
    button_name: buttonName,
  };
};

const saveHandler =
  (evid) =>
  ({ payload }, state) => {
    if (payload.type !== MODAL_TYPE_POST_SETTINGS || !payload.resolve) {
      return;
    }
    return handler(payload.resolve, state, evid, 'Save');
  };

// const saveAndPublishHandler = evid => ({ payload }, state) => handler(payload, state, evid, 'Publish');

export const hideModalEventHandler = (evid) => ({
  [CLOSE_MODAL]: saveHandler(evid),
  // [SAVE_AND_PUBLISH_POST]: saveAndPublishHandler(evid), //TODO
});
