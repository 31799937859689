import { get } from 'lodash';
import {
  FETCH_RECENT_POSTS_REQUEST,
  FETCH_RECENT_POSTS_SUCCESS,
  FETCH_RECENT_POSTS_FAILURE,
} from '../../../post-page/actions/fetch-recent-posts';
import {
  FETCH_SEARCH_SUCCESS,
  FETCH_SEARCH_FAILURE,
} from '../../../search/actions/fetch-search-types';
import {
  FETCH_ARCHIVE_REQUEST,
  FETCH_ARCHIVE_SUCCESS,
  FETCH_ARCHIVE_FAILURE,
} from '../../actions/fetch-archive';
import {
  FETCH_CATEGORY_POSTS_REQUEST,
  FETCH_CATEGORY_POSTS_SUCCESS,
  FETCH_CATEGORY_POSTS_FAILURE,
} from '../../actions/fetch-category-posts';
import {
  FETCH_FEED_POSTS_REQUEST,
  FETCH_FEED_POSTS_SUCCESS,
  FETCH_FEED_POSTS_FAILURE,
} from '../../actions/fetch-feed-posts';
import {
  FETCH_HASHTAG_POSTS_FAILURE,
  FETCH_HASHTAG_POSTS_REQUEST,
  FETCH_HASHTAG_POSTS_SUCCESS,
} from '../../actions/fetch-hashtag-posts';
import {
  FETCH_LAST_FEATURED_POST_REQUEST,
  FETCH_LAST_FEATURED_POST_SUCCESS,
  FETCH_LAST_FEATURED_POST_FAILURE,
} from '../../actions/fetch-last-featured-post';
import {
  FETCH_POST_REQUEST,
  FETCH_POST_SUCCESS,
  FETCH_POST_FAILURE,
} from '../../actions/fetch-post';
import {
  FETCH_TAG_POSTS_REQUEST,
  FETCH_TAG_POSTS_SUCCESS,
  FETCH_TAG_POSTS_FAILURE,
} from '../../actions/fetch-tag-posts';
import { SAVE_POST_DRAFT_REQUEST } from '../../actions/save-post-draft-types';
import { SET_IS_LOADING } from './is-loading-actions';

export default function isLoading(state = {}, action) {
  switch (action.type) {
    case SET_IS_LOADING:
      return setIsLoading(state, action.payload);

    case FETCH_POST_REQUEST: {
      return setIsLoading(state, {
        entity: 'post',
        id: action.payload.postSlug,
        isLoading: true,
      });
    }
    case FETCH_POST_SUCCESS:
    case FETCH_POST_FAILURE:
      return setIsLoading(state, {
        entity: 'post',
        id: action.payload.postSlug,
        isLoading: false,
      });

    case FETCH_LAST_FEATURED_POST_REQUEST: {
      return setIsLoading(state, {
        entity: 'last-featured-post',
        isLoading: true,
      });
    }
    case FETCH_LAST_FEATURED_POST_SUCCESS:
    case FETCH_LAST_FEATURED_POST_FAILURE:
      return setIsLoading(state, {
        entity: 'last-featured-post',
        isLoading: false,
      });

    case FETCH_FEED_POSTS_REQUEST: {
      return setIsLoading(state, {
        entity: 'feed-posts',
        isLoading: true,
      });
    }
    case FETCH_FEED_POSTS_SUCCESS:
    case FETCH_FEED_POSTS_FAILURE:
      return setIsLoading(state, {
        entity: 'feed-posts',
        isLoading: false,
      });

    case FETCH_RECENT_POSTS_REQUEST: {
      return setIsLoading(state, {
        entity: 'recent-posts',
        id: action.payload.postId,
        isLoading: true,
      });
    }

    case FETCH_RECENT_POSTS_SUCCESS:
    case FETCH_RECENT_POSTS_FAILURE:
      return setIsLoading(state, {
        entity: 'recent-posts',
        id: action.meta.postId,
        isLoading: false,
      });

    case FETCH_CATEGORY_POSTS_REQUEST: {
      return setIsLoading(state, {
        entity: 'category',
        id: get(action, 'payload.categoryId'),
        isLoading: true,
      });
    }
    case FETCH_CATEGORY_POSTS_SUCCESS:
      return setIsLoading(state, {
        entity: 'category',
        id: get(action, 'meta.categoryId'),
        isLoading: false,
      });

    case FETCH_CATEGORY_POSTS_FAILURE:
      return setIsLoading(state, {
        entity: 'category',
        id: get(action, 'payload.categoryId'),
        isLoading: false,
      });

    case FETCH_TAG_POSTS_REQUEST: {
      return setIsLoading(state, {
        entity: 'tag',
        id: get(action, 'payload.tagId'),
        isLoading: true,
      });
    }
    case FETCH_TAG_POSTS_SUCCESS:
      return setIsLoading(state, {
        entity: 'tag',
        id: get(action, 'meta.tagId'),
        isLoading: false,
      });
    case FETCH_TAG_POSTS_FAILURE:
      return setIsLoading(state, {
        entity: 'tag',
        id: get(action, 'payload.tagId'),
        isLoading: false,
      });

    case FETCH_SEARCH_SUCCESS:
    case FETCH_SEARCH_FAILURE:
      return setIsLoading(state, {
        entity: 'search',
        isLoading: false,
      });

    case SAVE_POST_DRAFT_REQUEST:
      return setIsLoading(state, {
        entity: 'postDraft',
        isLoading: true,
        id: get(action, 'payload.draftKey', null),
      });

    case FETCH_ARCHIVE_REQUEST: {
      return setIsLoading(state, {
        entity: 'archive',
        isLoading: true,
      });
    }
    case FETCH_ARCHIVE_SUCCESS:
    case FETCH_ARCHIVE_FAILURE:
      return setIsLoading(state, {
        entity: 'archive',
        isLoading: false,
      });

    case FETCH_HASHTAG_POSTS_REQUEST: {
      return setIsLoading(state, {
        entity: 'hashtag-posts',
        isLoading: true,
      });
    }
    case FETCH_HASHTAG_POSTS_SUCCESS:
    case FETCH_HASHTAG_POSTS_FAILURE:
      return setIsLoading(state, {
        entity: 'hashtag-posts',
        isLoading: false,
      });

    default:
      return state;
  }
}

function setIsLoading(state, { entity, id = '', isLoading }) {
  const entityState = id
    ? {
        ...state[entity],
        [id]: isLoading,
      }
    : isLoading;

  return {
    ...state,
    [entity]: entityState,
  };
}
