import { CATEGORY_MENU_WIDGET_SUCCESS } from './actions';

const categoryMenuWidgetReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case CATEGORY_MENU_WIDGET_SUCCESS:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default categoryMenuWidgetReducer;
