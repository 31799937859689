import { get } from 'lodash';
import { createAction } from '@wix/communities-blog-client-common';
import { type CommonThunkAction } from '@app/types/common-thunk-action.type';
import { type PlatformApi } from '../../controller/platform-api';
import { getMultilingualLanguage } from '../../services/get-current-site-language';
import { type ControllerConfig } from '../../types';

export const SET_LOCALE = 'locale/SET';
export const setLocale = createAction(SET_LOCALE);

export const SET_QUERY_LOCALE = 'locale/SET_QUERY';
export const setQueryLocale = createAction(SET_QUERY_LOCALE);

export const setQueryLocaleAction =
  (platformApi: PlatformApi, appParams: ControllerConfig['appParams']): CommonThunkAction<void> =>
  async (dispatch) => {
    if (!platformApi || !appParams) {
      return;
    }

    const isMultilingualInstalled = get(platformApi, 'window.multilingual.isEnabled');
    if (!isMultilingualInstalled) {
      return;
    }

    const multilingualLanguage = getMultilingualLanguage(platformApi);
    if (multilingualLanguage) {
      dispatch(setQueryLocale(multilingualLanguage));
    }
  };
