import { HIDE_MESSAGE, SHOW_MESSAGE } from '../../messages/framework/store/message-actions';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case SHOW_MESSAGE:
      return action.payload;
    case HIDE_MESSAGE:
      return {};
    default:
      return state;
  }
};
