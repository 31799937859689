import { atob } from '@wix/communities-blog-client-common';

export const parseInstance = (instance) => {
  if (!instance) {
    throw new Error('Missing instance');
  }
  const [, base64data] = instance.split('.', 2);
  const data = atob(base64data);
  return JSON.parse(data);
};
