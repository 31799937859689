import type Wix from '@wix/native-components-infra/dist/src/types/wix-sdk';
import { type CommonThunkAction } from '@app/types/common-thunk-action.type';

const trackEvent =
  (name: string, params: any): CommonThunkAction<void> =>
  async (dispatch, getState, { platformApi }) => {
    platformApi.window.trackEvent(name as Wix.ITrackEventName, params);
  };

export default trackEvent;
