import { applyMiddleware, createStore, type Store } from 'redux';
import thunk from 'redux-thunk';
import { type PlatformApi } from '@app/external/common/controller/platform-api';
import { postMetadataMiddleware } from '../../common/middleware/post-metadata-middleware';
import createRequests from '../../common/services/create-requests';
import { type ControllerConfig, type FedopsLogger, type FlowAPI } from '../../common/types';
import { reducers } from '../store/reducers';
import type { RelatedPostsThunkExtraArgs } from '../types';

type CreateReduxStoreParams = {
  fedopsLogger: FedopsLogger;
  isSSR: boolean;
  language: string;
  bundleName: string;
  controllerConfig: ControllerConfig;
  platformApi: PlatformApi;
  flowAPI: FlowAPI;
};

export function createReduxStore({
  platformApi,
  fedopsLogger,
  isSSR,
  language,
  bundleName,
  controllerConfig,
  flowAPI,
}: CreateReduxStoreParams) {
  const { appParams, compId } = controllerConfig;
  const { request } = createRequests({
    platformApi,
    bundleName,
    getStore: () => store,
    flowAPI,
  });

  let store: Store = undefined!;

  const middleware = [
    thunk.withExtraArgument({
      request,
      platformApi,
      compId,
      appParams,
      fedopsLogger,
      httpClient: flowAPI.httpClient,
    } satisfies RelatedPostsThunkExtraArgs),
    postMetadataMiddleware({ isSSR, language, localizePosts: true }),
  ];
  const initialState = { experiments: flowAPI.essentials.experiments.all() };

  store = createStore(reducers, initialState, applyMiddleware(...middleware));

  return store;
}
