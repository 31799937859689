import type { AppData } from '../../viewer.app';
import blocksModel from './model';

const selectors = {
  counters: '#postCountersNew1',
  categories: '#categories1',
  tags: '#tags1',
  metadata: '#metadata1',
} as const;

export default blocksModel.createController((params) => {
  const { $w } = params;
  const appData = params.appData as AppData;

  return {
    pageReady: async () => {
      const ui = {
        categories: $w(selectors.categories),
        tags: $w(selectors.tags),
        metadata: $w(selectors.metadata),
        counters: $w(selectors.counters),
      };

      await appData.subjects.postPageRenderModel.subscribe((model) => {
        ui.categories.initialize(model);
        ui.categories.hostWidget = 'footer';

        ui.tags.initialize(model);
        ui.metadata.initialize(model);
        if ('initialize' in ui.counters) {
          ui.counters.initialize(model);
        }
      });
    },
    exports: {
      counters: () => $w(selectors.counters),
    },
  };
});
