import { createAction } from '@wix/communities-blog-client-common';

export const FETCH_WRITERS_SUCCESS = 'writers/FETCH_SUCCESS';

const fetchWritersRequest = createAction('writers/FETCH_REQUEST');
const fetchWritersSuccess = createAction(FETCH_WRITERS_SUCCESS);
const fetchWritersFailure = createAction('writers/FETCH_FAILURE');

export const fetchWriters =
  () =>
  (dispatch, _getState, { request }) => {
    dispatch(fetchWritersRequest());

    return request('/_api/users?roles=admin,writer,editor')
      .then((writers) => dispatch(fetchWritersSuccess(writers)))
      .catch(() => dispatch(fetchWritersFailure()));
  };
