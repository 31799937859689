import { type Dispatch } from 'redux';
import { type HttpClient } from '@wix/yoshi-flow-editor';
import { getBlogCache } from '@wix/ambassador-blog-v1-blog-cache/http';
import { createAction } from '@wix/communities-blog-client-common';
import { type GetState } from '../../types';
import {
  getMetaSiteId,
  getIsDemoMode,
  getSiteIsTemplate,
} from '../instance-values/instance-values-selectors';
import {
  FETCH_SITE_PROPERTIES_REQUEST,
  FETCH_SITE_PROPERTIES_SUCCESS,
  FETCH_SITE_PROPERTIES_FAILURE,
} from './site-properties-types';

const fetchSitePropertiesRequest = createAction(FETCH_SITE_PROPERTIES_REQUEST);
const fetchSitePropertiesSuccess = createAction(FETCH_SITE_PROPERTIES_SUCCESS);
const fetchSitePropertiesFailure = createAction(FETCH_SITE_PROPERTIES_FAILURE);

export const fetchSiteProperties = () => {
  return (dispatch: Dispatch, getState: GetState, { httpClient }: { httpClient: HttpClient }) => {
    dispatch(fetchSitePropertiesRequest());

    const state = getState();
    const shouldSkipFetch =
      !getMetaSiteId(state) || getIsDemoMode(state) || getSiteIsTemplate(state);

    if (shouldSkipFetch) {
      dispatch(fetchSitePropertiesSuccess({}));
      return;
    }

    const promise = httpClient.request(getBlogCache({}));

    return promise
      .then(
        (response) =>
          dispatch(fetchSitePropertiesSuccess(response?.data?.blogCache?.siteProperties)),
        () => dispatch(fetchSitePropertiesFailure()),
      )
      .then(() => Promise.resolve());
  };
};
