import type { AppData } from '../../viewer.app';
import blocksModel from './model';
import { PostContentController } from './post-content-controller';

export default blocksModel.createController(({ $w, flowAPI, appData: _appData }) => {
  const appData = _appData as AppData;
  let isPageReady = false;

  return {
    pageReady: async () => {
      if (isPageReady) {
        return;
      }

      isPageReady = true;

      const controller = new PostContentController($w, flowAPI, appData.platformApi);

      await appData.subjects.postPageRenderModel.subscribe((model) => {
        controller.applyStateToUi(model);
      });
    },
    exports: {},
  };
});
