import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'Post Tags',
  props: {},
  methods: {
    initialize: {
      description: 'Initialize the post tags',
    },
  },
  events: {},
});
