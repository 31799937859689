import { FETCH_LAST_FEATURED_POST_SUCCESS } from '../../common/actions/fetch-last-featured-post';

const lastFeaturedPost = (state = null, { type, payload } = {}) => {
  switch (type) {
    case FETCH_LAST_FEATURED_POST_SUCCESS:
      return payload || null;
    default:
      return state;
  }
};

export default lastFeaturedPost;
