import { pick } from 'lodash';
import { resolveId } from '@wix/communities-blog-client-common';

import { FETCH_RECENT_POSTS_SUCCESS } from '../../../post-page/actions/fetch-recent-posts';
import { FETCH_RELATED_POSTS_SUCCESS } from '../../../post-page/actions/fetch-related-posts';
import { FETCH_ARCHIVE_SUCCESS } from '../../actions/fetch-archive';
import { FETCH_CATEGORY_POSTS_SUCCESS } from '../../actions/fetch-category-posts';
import { FETCH_FEED_POSTS_SUCCESS } from '../../actions/fetch-feed-posts';
import { FETCH_HASHTAG_POSTS_SUCCESS } from '../../actions/fetch-hashtag-posts';
import { FETCH_POST_METADATA_SUCCESS } from '../../actions/fetch-post-metadata';
import { FETCH_TAG_POSTS_SUCCESS } from '../../actions/fetch-tag-posts';
import { SET_POSTS } from '../../actions/set-posts';

export default function postCounters(state = {}, { type, payload }) {
  switch (type) {
    case FETCH_POST_METADATA_SUCCESS:
      return post(state, payload);
    case FETCH_FEED_POSTS_SUCCESS:
    case FETCH_RECENT_POSTS_SUCCESS:
    case FETCH_RELATED_POSTS_SUCCESS:
    case FETCH_CATEGORY_POSTS_SUCCESS:
    case FETCH_TAG_POSTS_SUCCESS:
    case FETCH_ARCHIVE_SUCCESS:
    case FETCH_HASHTAG_POSTS_SUCCESS:
    case SET_POSTS:
      return posts(state, payload);
    default:
      return state;
  }
}

function post(state, metadata) {
  const { postPageMetadata, relatedPostsMetadata } = metadata;
  const posts = [postPageMetadata, ...relatedPostsMetadata];

  const postsById = posts.reduce((result, post) => {
    if (post.id in result) {
      return result;
    }

    result[post.id] = post.postMetadata;

    return result;
  }, {});

  return { ...state, ...postsById };
}

function posts(state, posts) {
  return {
    ...state,
    ...posts.reduce((result, post) => {
      result[resolveId(post)] = getCounters(post);
      return result;
    }, {}),
  };
}

function getCounters(post) {
  return pick(post, ['viewCount', 'totalComments']);
}
