import { CLOSE_MODAL } from './modal-actions';

export const createModalClosedMiddleware =
  ({ createShowMessage, modalResolverMapByType }) =>
  (store) => {
    const showMessage = createShowMessage(store);

    return (next) => (action) => {
      if (action.type === CLOSE_MODAL) {
        const {
          payload: { type, resolve },
        } = action;

        const resolver = modalResolverMapByType[type];
        if (resolver && !(resolve === false || resolve === undefined)) {
          store.dispatch(
            resolver(resolve, {
              showMessage,
            }),
          );
        }
      }

      next(action);
    };
  };
