import { type Store } from 'redux';
import { type PlatformApi } from '../controller/platform-api';
import { setInstance } from '../store/basic-params/basic-params-actions';
import { initializeInstanceValues } from '../store/instance-values/instance-values-actions';
import { type ControllerConfig } from '../types';

export default function listenToInstanceChange(
  platformApi: PlatformApi,
  appParams: ControllerConfig['appParams'],
  store: Store,
) {
  if (!platformApi.site.onInstanceChanged) {
    return;
  }

  platformApi.site.onInstanceChanged(({ instance }) => {
    store.dispatch(initializeInstanceValues(instance));
    store.dispatch(setInstance(instance));
  }, appParams.appDefinitionId);
}
