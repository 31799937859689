import { setViewedUser } from './viewed-user-actions';

export default function viewedUser(state = null, action: any) {
  switch (action.type) {
    case setViewedUser.type:
      return action.payload;
    default:
      return state;
  }
}
