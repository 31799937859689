import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'Post Ratings',
  props: {},
  methods: {
    initialize: {
      params: [
        {
          name: 'model',
          description: 'The model to initialize the widget',
        },
      ],
    },
  },
  events: {},
});
