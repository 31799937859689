import { getPostPageMetadata } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/http';
import { createAction } from '@wix/communities-blog-client-common';
import type { CommonThunkAction } from '@app/types/common-thunk-action.type';
import { getLanguageCode } from '../selectors/locale-selectors';

export const FETCH_POST_METADATA_SUCCESS = 'postMetadata/FETCH_SUCCESS';
export const FETCH_POST_METADATA_REQUEST = 'postMetadata/FETCH_REQUEST';
export const FETCH_POST_METADATA_FAILURE = 'postMetadata/FETCH_FAILURE';
export const RESET_POST_METADATA_LOADED = 'postMetadata/RESET_LOADED';

export const fetchPostMetadataRequest = createAction(FETCH_POST_METADATA_REQUEST);
export const fetchPostMetadataSuccess = createAction(FETCH_POST_METADATA_SUCCESS);
export const resetPostMetadataLaoded = createAction(RESET_POST_METADATA_LOADED);
const fetchPostMetadataFailure = createAction(FETCH_POST_METADATA_FAILURE);

export const fetchPostMetadata =
  (postSlug: string): CommonThunkAction<unknown> =>
  async (dispatch, getState, { httpClient }) => {
    dispatch(fetchPostMetadataRequest());
    const languageCode = getLanguageCode(getState());

    try {
      const result = await httpClient.request(
        getPostPageMetadata({ postId: postSlug, languageCode, skipViewCountIncrement: true }),
      );
      dispatch(fetchPostMetadataSuccess(result.data));
    } catch (error) {
      dispatch(fetchPostMetadataFailure(error));
    }
  };
