import { compact } from 'lodash';
import { type Dispatch } from 'redux';
import { type IHttpClient } from '@wix/yoshi-flow-editor';
import { listDemoPosts } from '@wix/ambassador-blog-v3-post/http';
import { PostFieldField, type Post } from '@wix/ambassador-blog-v3-post/types';
import {
  BLOG_HEADER_TOTAL_RESULTS,
  getCurrentUser,
  type AggregatedResponse,
} from '@wix/communities-blog-client-common';

import { type AggregatorRequest } from '@app/external/post-list-widget/types';
import { type PlatformApi } from '../controller/platform-api';
import { getLocale, getQueryLocale } from '../selectors/locale-selectors';
import { fetchCurrentUser } from '../store/auth/fetch-current-user';
import { type AppState } from '../types';
import { normalizePostV3 } from './post-utils';
import { encodeURISlug } from './slug';

type DemoPostsQuery = {
  page?: number;
  pageSize?: number;
  slugs?: string[];
  excludeIds?: string[];
  language?: string;
  featuredOnly?: boolean;
  categoryId?: string;
  tagId?: string;
};

export const getDemoPosts = async ({
  getState,
  dispatch,
  httpClient,
  aggregatorRequest,
  platformApi,
  query,
}: {
  getState: () => AppState;
  dispatch: Dispatch;
  httpClient: IHttpClient;
  aggregatorRequest: AggregatorRequest;
  platformApi: PlatformApi;
  query: DemoPostsQuery;
}) => {
  try {
    const isFiltered = Boolean(query.featuredOnly || query.categoryId || query.tagId);

    const [hasPosts, response] = await Promise.all([
      isFiltered && getHasRealPosts({ aggregatorRequest, language: query.language }),
      fetchDemoPosts({
        getState,
        httpClient,
        query,
      }),
      !getCurrentUser(getState()) &&
        dispatch(fetchCurrentUser(platformApi.user.currentUser.instance)),
    ]);

    return isFiltered && hasPosts ? toResponse([]) : response;
  } catch (ex) {
    console.error(ex);
    return toResponse([]);
  }
};

const fetchDemoPosts = async ({
  getState,
  httpClient,
  query: { page = 1, pageSize = 6, slugs, excludeIds, language, featuredOnly, categoryId, tagId },
}: {
  getState: () => AppState;
  httpClient: IHttpClient;
  query: DemoPostsQuery;
}) => {
  const state = getState();

  if (!language) {
    language = getQueryLocale(state) || getLocale(state);
  }

  const filters = !slugs
    ? {
        paging: {
          offset: (page - 1) * pageSize,
          limit: pageSize,
        },
        ...(featuredOnly ? { featured: true } : {}),
        ...(categoryId ? { categoryIds: [categoryId] } : {}),
        ...(tagId ? { tagIds: [tagId] } : {}),
      }
    : {};

  const { data } = await httpClient.request(
    listDemoPosts({
      language,
      fieldsets: [PostFieldField.CONTENT],
      ...filters,
    }),
  );

  const posts = applyFilters({ slugs, excludeIds }, data.posts ?? []);

  return toResponse(normalizePosts({ posts, getState }), data.metaData?.total ?? posts.length);
};

const applyFilters = (
  {
    slugs,
    excludeIds,
    categoryId,
    tagId,
  }: {
    slugs?: string[];
    excludeIds?: string[];
    categoryId?: string;
    tagId?: string;
  },
  posts: Post[],
) => {
  const filters = compact([
    Array.isArray(slugs) && ((x: Post) => slugs.includes(encodeURISlug(x.slug!))),
    categoryId && ((x: Post) => x.categoryIds?.includes(categoryId)),
    tagId && ((x: Post) => x.tagIds?.includes(tagId)),
    Array.isArray(excludeIds) && ((x: Post) => !excludeIds.includes(x.id!)),
  ]);

  return filters.reduce((acc, predicate) => acc.filter(predicate), posts);
};

const normalizePosts = ({ posts, getState }: { posts: Post[]; getState: () => AppState }) => {
  const firstPublishedDate = new Date();
  firstPublishedDate.setMinutes(firstPublishedDate.getMinutes() - 1);
  const lastPublishedDate = new Date();
  lastPublishedDate.setHours(firstPublishedDate.getHours() - 24);
  const owner = getCurrentUser(getState()) || {};

  return posts.map((post) => {
    post.firstPublishedDate = firstPublishedDate.toISOString() as any;
    post.lastPublishedDate = lastPublishedDate.toISOString() as any;
    // @ts-expect-error
    post.owner = owner;
    // @ts-expect-error
    if (!post.coverImage) {
      // @ts-expect-error
      post.status = 'published';
      return normalizePostV3(post);
    }
    return post;
  });
};

const toResponse = <T>(posts: T[], total = 0) => ({
  posts,
  headers: { [BLOG_HEADER_TOTAL_RESULTS.toLowerCase()]: total },
});

const getHasRealPosts = async ({
  language,
  aggregatorRequest,
}: {
  language?: string;
  aggregatorRequest: AggregatorRequest;
}) => {
  const { posts } = await aggregatorRequest<{
    posts: AggregatedResponse<unknown[]>;
  }>(
    `/v1/post-list-widget/render-model?${[
      'postLimit=1',
      language && `language=${language}`,
      'fields=posts',
    ]
      .filter(Boolean)
      .join('&')}`,
    { throwOnInvalidJson: true },
  );
  return posts.body.length > 0;
};
