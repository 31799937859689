import { RESOLVE_PROMISIFIED_ACTION } from './promisified-actions-actions';

export function promisifiedActionsResults(state = {}, action) {
  switch (action.type) {
    case RESOLVE_PROMISIFIED_ACTION:
      const { payload, isResolved, correlationId } = action.payload;
      return {
        ...state,
        [correlationId]: {
          payload,
          isResolved,
        },
      };

    default:
      return state;
  }
}
