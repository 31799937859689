import { SUBMIT_FAST_FORM } from './fast-form-actions';

export function fastFormSubmit(state = null, action) {
  switch (action.type) {
    case SUBMIT_FAST_FORM:
      return action.payload;
    default:
      return state;
  }
}
