import { resolveId, createAction } from '@wix/communities-blog-client-common';

export const SET_POST_EDITOR_POST = 'postEditor/SET_POST';
export const UPDATE_POST_EDITOR_POST = 'postEditor/UPDATE_POST';
export const CLEAN_POST_EDITOR_STATE = 'postEditor/CLEAN_STATE';
export const SET_IS_EDITOR_BUSY = 'postEditor/SET_IS_EDITOR_BUSY';

export const setIsEditorBusy = createAction(SET_IS_EDITOR_BUSY);

export const cleanPostEditorState = createAction(CLEAN_POST_EDITOR_STATE);

const getDraftKey = (post) => resolveId(post) || String(new Date().getTime());

export const setPostEditorPost = createAction(SET_POST_EDITOR_POST, (post = {}) => ({
  post,
  draftKey: getDraftKey(post),
  initialDraftKey: getDraftKey(post),
  initialPost: post,
}));

export const updatePostEditorPost = createAction(UPDATE_POST_EDITOR_POST, (post = {}) => ({
  post,
  draftKey: resolveId(post),
}));
