import { PAGE_OPENED } from '../../actions/page-opened';
import { FEED_PAGE, POST_PAGE } from '../../services/detect-route';

const handler = (action) => {
  const {
    payload: { isEditor, page, isMobile },
  } = action;
  if (isEditor && [FEED_PAGE, POST_PAGE].includes(page)) {
    return {
      evid: 399,
      blog_view: FEED_PAGE === page ? 'Blog Feed' : 'Post Page',
      editor_view_mode: isMobile ? 'Mobile' : 'Desktop',
      eventMeta: {
        description:
          FEED_PAGE === page ? 'Blog feed in editor opened' : 'Post page in editor opened',
      },
    };
  }
};

export const usersPageOpenedEvent = { [PAGE_OPENED]: handler };
