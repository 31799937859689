import { DELETE_POST_SUCCESS } from '../../actions/delete-post';
import { getPostByIdOrSlug } from '../../selectors/post-selectors';
import { categoryIds, isPublished } from '../event-helpers';

const handler = (action, state) => {
  const post = getPostByIdOrSlug(state, action.payload);
  return {
    evid: 206,
    category_ids: categoryIds(post),
    is_published: isPublished(post),
    post_stable_id: post.id,
    is_demo: post.isDemo,
    eventMeta: {
      description: 'post deleted',
    },
  };
};

export const uouDeletePostSuccessEvent = { [DELETE_POST_SUCCESS]: handler };
