import { BLOG_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { FEED_PAGE_URLS, WP_BLOGS } from '@wix/communities-universal/dist/src/constants/wix-blogs';
import { type PlatformApi } from '@app/external/common/controller/platform-api';

export const getSectionUrl = async ({
  platformApi,
  instanceId,
  sectionId,
}: {
  platformApi: PlatformApi;
  instanceId: string;
  sectionId: string;
}) => {
  if (WP_BLOGS.includes(instanceId)) {
    return FEED_PAGE_URLS[instanceId];
  }

  const { url } = await platformApi.site.getSectionUrl({
    sectionId,
    appDefinitionId: BLOG_APP_ID,
  });
  return url?.split('?')[0] ?? '';
};
