import { BLOG_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';

// Single duplexer instance should be reused across app so there would be only one socket open,
// so instance creation function is memoized
let memoizedDuplexerClient;

export async function createDuplexerClient({ getInstance }) {
  if (memoizedDuplexerClient) {
    return memoizedDuplexerClient;
  }
  const { Duplexer } = await import(/* webpackChunkName: "duplexer" */ './duplexer');

  memoizedDuplexerClient = new Duplexer(`duplexer.wix.com`, {
    instanceUpdater: {
      getInstance,
    },
  });
  return memoizedDuplexerClient;
}

export async function createDuplexerConnection({ getInstance }) {
  const client = await createDuplexerClient({ getInstance });
  return client.connect({ appDefId: BLOG_APP_ID });
}
