import { CATEGORY_LABEL_CLICKED } from '../../actions/category-label-clicked';

const handler = (action) => {
  const category = action.payload;

  return {
    evid: 220,
    category_id: category.id,
    category_name: category.menuLabel,
    is_main: true,
    eventMeta: {
      description: 'category label click',
    },
  };
};

export const uouCategoryLabelClickEvent = { [CATEGORY_LABEL_CLICKED]: handler };
