import { TAG_CLICKED } from '../../actions/tag-clicked';

const handler = ({ payload }) => {
  const { label, id, postId, isDemo } = payload;

  return {
    evid: 212,
    post_stable_id: postId,
    tag_name: label,
    tagId: id,
    is_demo: isDemo,
    eventMeta: {
      description: 'tag clicked',
    },
  };
};

export const uouTagClickedEvent = { [TAG_CLICKED]: handler };
