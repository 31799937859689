export const WS_EMIT = 'ws/EMIT';
export const WS_JOIN = 'ws/JOIN';
export const WS_LEAVE = 'ws/LEAVE';

export function joinChannel(channel, hasClientEvents = false) {
  return {
    type: WS_JOIN,
    payload: { channel, hasClientEvents },
  };
}

export function leaveChannel(channel) {
  return {
    type: WS_LEAVE,
    payload: { channel },
  };
}
