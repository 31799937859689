import { formatDate, formatDateICU } from '@wix/communities-blog-client-common';
import { type PlatformApi } from '@app/external/common/controller/platform-api';

export class DateFormatter {
  constructor(private readonly platformApi: PlatformApi) {}

  format(date: Date) {
    return formatDate({
      date: date.toString(),
      lng: this.platformApi.site.language,
      timezone: this.platformApi.site.timezone,
    });
  }

  formatICU(date: Date) {
    return formatDateICU({
      date: date.toString(),
      lng: this.platformApi.site.language,
      timezone: this.platformApi.site.timezone,
    });
  }
}
