import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'Post Footer',
  props: {},
  methods: {
    counters: {
      description: 'Returns the counters widget',
    },
  },
  events: {},
});
