import { get } from 'lodash';
import { type PlatformApi } from '../controller/platform-api';
import { isVerboseDebugAllowed } from './debug';

const simulateError = (queryParam: string, isSSR: boolean, origin: string) => {
  if (queryParam === 'ssr' && isSSR) {
    throw new Error(`SSR error in ${origin}`);
  } else if (queryParam === 'csr' && !isSSR) {
    throw new Error(`CSR error in ${origin}`);
  }
};

export const simulateControllerError = (platformApi: PlatformApi, origin: string) => {
  if (!isVerboseDebugAllowed) {
    return;
  }
  const isSSR = get(platformApi, 'window.rendering.env') === 'backend';
  const queryParam = get(platformApi, 'location.query.simulateError');
  simulateError(queryParam, isSSR, origin);
};
