import { combineReducers, applyMiddleware, createStore, type Store } from 'redux';
import thunk from 'redux-thunk';
import { type PlatformApi } from '@app/external/common/controller/platform-api';
import { type ControllerConfig, type FlowAPI } from '@app/external/common/types';
import { type CommonThunkArgs } from '@app/types/common-thunk-action.type';
import commonReducers from '../../common/reducers';
import createRequests from '../../common/services/create-requests';
import categoryMenuWidgetReducer from '../aggregated-category-menu/reducer';

export function createReduxStore({
  appParams,
  platformApi,
  compId,
  fedopsLogger,
  bundleName,
  flowAPI,
}: {
  appParams: ControllerConfig['appParams'];
  platformApi: PlatformApi;
  compId: ControllerConfig['compId'];
  fedopsLogger: FlowAPI['fedops'];
  bundleName: string;
  flowAPI: FlowAPI;
}) {
  const p: {
    store?: Store;
  } = {};

  const { request } = createRequests({
    platformApi,
    bundleName,
    getStore: () => p.store!,
    flowAPI,
  });

  const middleware = [
    thunk.withExtraArgument({
      request,
      platformApi,
      compId,
      appParams,
      fedopsLogger,
      httpClient: flowAPI.httpClient,
    } satisfies CommonThunkArgs),
  ];

  const reducers = combineReducers({
    ...commonReducers,
    categoryMenuWidget: categoryMenuWidgetReducer,
  });

  const initialState = { experiments: flowAPI.essentials.experiments.all() };

  p.store = createStore(reducers, initialState, applyMiddleware(...middleware));

  return p.store;
}
