import { type AppState } from '../types';
import { SET_ROUTER_MATCH } from './router-actions';

const initialState: AppState['router'] = {
  currentMatch: {},
  previousMatches: [],
};

export const router = (
  state: AppState['router'] = initialState,
  action: any,
): AppState['router'] => {
  switch (action.type) {
    case SET_ROUTER_MATCH:
      return {
        currentMatch: action.payload,
        previousMatches: [action.payload, ...state.previousMatches].slice(0, 10),
      };
    default:
      return state;
  }
};
