import { type HttpResponse, type IHttpClient } from '@wix/yoshi-flow-editor';
import { getPostBySlug } from '@wix/ambassador-blog-v3-post/http';
import { resetPostMetadataLaoded } from '@app/external/common/actions/fetch-post-metadata';
import { getIsMetadataLoaded } from '@app/external/common/store/is-loaded/is-loaded-selectors';
import { type AppData } from '../../../../viewer.app';
import { completeFetchPost, fetchPost, preFetchPost } from '../../../common/actions/fetch-post';
import type { PlatformApi } from '../../../common/controller/platform-api';
import { resolvePostSlug } from '../../../common/services/slug';
import {
  type AppStore,
  type ControllerConfig,
  type FlowAPI,
  type NormalizedPost,
  type RouteResolverFn,
} from '../../../common/types';
import { setReadingSessionId } from '../../actions/reading-session-id-actions';
import { ROUTE_404 } from '../../constants/routes';
import { postPageRouterPostHandler as handlePostPageRouterPost } from './post-page-router-post-handler';
import { createSetPostIdToSlotsFunction } from './slot-handler';

interface CreatePostPageRouterParams {
  store: AppStore;
  platformApi: PlatformApi;
  appData: AppData;
  flowAPI: FlowAPI;
  slotAPIFactory: Record<string, any>;
  controllerConfig: ControllerConfig;
}

const fetchPostIdBySlug =
  ({ httpClient, postSlug }: { httpClient: IHttpClient; postSlug: string }) =>
  () =>
    httpClient
      .request(getPostBySlug({ slug: postSlug }))
      .then((response) => {
        return response.data.post?.id;
      })
      .catch(() => undefined);

export const createPostPageRouter =
  ({
    store,
    platformApi,
    appData,
    flowAPI,
    slotAPIFactory,
    controllerConfig,
  }: CreatePostPageRouterParams): RouteResolverFn =>
  async ({ params }, redirect, { preFetch, preFetchResult }) => {
    const postSlug = resolvePostSlug(params);

    if (!postSlug) {
      throw new Error('Post slug is not available');
    }

    const { httpClient } = flowAPI;
    const warmupData = platformApi.window.warmupData;
    const isSSR = platformApi.window.rendering.env === 'backend';

    const setPostIdToSlots = createSetPostIdToSlotsFunction(slotAPIFactory);

    const fetchPostIdBySlugPromise = fetchPostIdBySlug({
      httpClient,
      postSlug,
    });

    const postId = !isSSR ? warmupData.get(postSlug) : undefined;

    if (postId) {
      setPostIdToSlots(postId);
    } else if (isSSR || !postId) {
      const id = await fetchPostIdBySlugPromise();
      if (isSSR) {
        warmupData.set(postSlug, id);
      }
      setPostIdToSlots(id);
    }

    const state = store.getState();
    const isMetadataLoaded = getIsMetadataLoaded(state);

    if (isMetadataLoaded) {
      store.dispatch(resetPostMetadataLaoded());
    }

    if (preFetch) {
      return store.dispatch(preFetchPost(postSlug));
    }

    const readingSessionId = `${parseInt(`${Math.random() * 10000000}`, 10)}-${Date.now()}`;
    store.dispatch(setReadingSessionId(readingSessionId));

    return (
      preFetchResult
        ? store.dispatch(completeFetchPost(postSlug, preFetchResult))
        : store.dispatch(fetchPost(postSlug))
    )
      .then((post: NormalizedPost) => {
        return handlePostPageRouterPost({
          post,
          store,
          appData,
          platformApi,
          flowAPI,
          redirect,
          controllerConfig,
        });
      })
      .catch((error: HttpResponse) => {
        if (error.status === 401) {
          return redirect(`/login?redirect=/${postSlug}`);
        }

        if (error.status === 404) {
          return redirect(ROUTE_404);
        }

        throw error;
      });
  };
