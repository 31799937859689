import { get } from 'lodash';
import { type PlatformApi } from '../controller/platform-api';
import { type FlowAPI } from '../types';
import { validateLanguage } from './validate-language';

export const getCurrentSiteLanguage = (platformApi: PlatformApi, flowAPI: FlowAPI) => {
  return validateLanguage(getMultilingualLanguage(platformApi) || flowAPI.environment.language);
};

export const getMultilingualLanguage = (platformApi: PlatformApi) =>
  get(platformApi, 'window.multilingual.currentLanguage');
