import { isEmpty, isNil, omitBy } from 'lodash';
import { bindActionCreators, type Store } from 'redux';
import { getPricingPlansPublicAPI } from '@wix/pricing-plans-tpa-api';
import { type IWixAPI } from '@wix/yoshi-flow-editor';
import {
  BLOG_APP_ID,
  SANTA_MEMBERS_APP_ID,
  BLOG_POST_PAGE_SECTION_ID,
} from '@wix/communities-universal/dist/src/constants/appsConfig';
import { type SeoTagsPayload } from '@wix/native-components-infra/dist/src/types/types';
import {
  FETCH_BLOG_DATA_SUCCESS,
  biFileDownloaded,
  biFileUploaded,
  fetchTPASettings,
  getCurrentUser,
  getCustomPostEditUrl,
  fetchAppDataSuccess,
} from '@wix/communities-blog-client-common';
import {
  fetchArchivePostsWithAdapterPromisified,
  fetchCategoryPostsWithAdapterPromisified,
  fetchFeedPostsWithAdapterPromisified,
  fetchHashtagPostsWithAdapterPromisified,
  fetchTagPostsWithAdapterPromisified,
} from '../../feed-page/actions/fetch-feed-render-model';
import { actionsClosed } from '../actions/actions-closed';
import { actionsOpened } from '../actions/actions-opened';
import { emitBlogOpened } from '../actions/blog-socket';
import { buttonClicked } from '../actions/button-clicked';
import { categoryLabelClicked } from '../actions/category-label-clicked';
import { fetchMentionsPromisified } from '../actions/fetch-mentions';
import { fetchPostMetadata } from '../actions/fetch-post-metadata';
import { headerSearchClosed, headerSearchOpened } from '../actions/header-search';
import { showJoinCommunityPopup } from '../actions/join-community';
import {
  navigateInPreview,
  navigateProGalleryWithinPostPage,
  navigateToCustomPostUrl,
  navigateToSearch,
  navigateToSectionInPreview,
  navigateWithinBlog,
  navigateWithinPostPage,
} from '../actions/navigate-within-blog';
import { pageOpened } from '../actions/page-opened';
import { postPageMoreButtonClicked } from '../actions/post-page-more-button-clicked';
import { postPageMoreButtonOptionClicked } from '../actions/post-page-more-button-option-clicked';
import { postPageSocialShareClicked } from '../actions/post-page-social-share-clicked';
import { requestLogin, requestLoginPromisified } from '../actions/request-login';
import { searchSubmitted } from '../actions/search';
import trackEvent from '../actions/track-event';
import { BUTTON_ACTION_CLICK } from '../bi-events/bi-buttons';
import { fastFormSubmitCallback } from '../components/fast-form/store/fast-form-actions';
import { REFERRAL_BLOG_POST_PAYWALL } from '../constants/paid-plans';
import { messageActions } from '../messages/framework/initialize-store';
import { modalActions } from '../modals/framework/initialize-store';
import { type Router } from '../router';
import { getCurrentMatchEncodedPathname } from '../router/router-selectors';
import { isFeedAdapterEnabled, isPostPageAdapterEnabled } from '../selectors/experiment-selectors';
import { getQueryLocale } from '../selectors/locale-selectors';
import { setAppConfig } from '../store/app-config/app-config-actions';
import { SET_APP_SETTINGS, setAppSettings } from '../store/app-settings/app-settings-actions';
import { fetchCurrentUser } from '../store/auth/fetch-current-user';
import { setUser } from '../store/auth/set-user';
import { setBasicParams } from '../store/basic-params/basic-params-actions';
import fetchCategories from '../store/categories/fetch-categories';
import { setCommunitiesContext } from '../store/communities-context/communities-context-actions';
import { setCustomRoutes } from '../store/custom-routes/custom-routes-actions';
import { setEnableLinkfify } from '../store/editor-config/editor-config-actions';
import { showHeaderLogin } from '../store/header-widget/show-header-login';
import {
  SET_INSTANCE_VALUES,
  initializeInstanceValues,
} from '../store/instance-values/instance-values-actions';
import { getInstanceId, getSiteMemberId } from '../store/instance-values/instance-values-selectors';
import { setIsLoaded } from '../store/is-loaded/is-loaded-actions';
import { setIsLoading } from '../store/is-loading/is-loading-actions';
import { setLocale, setQueryLocaleAction } from '../store/locale/locale-actions';
import { initializeMembersNavigation } from '../store/ma-navigation/ma-navigation-actions';
import { paginationClearCollection } from '../store/pagination/pagination-actions';
import { FETCH_TOPOLOGY_SUCCESS, fetchTopology } from '../store/topology/topology-actions';
import { fetchTranslations } from '../store/translations/translations-actions';
import { waitForAction } from '../store/wait-for-action';
import { type ControllerConfig, type FlowAPI } from '../types';
import { doRouting, getInstance, resolveUser } from './helpers';
import {
  addTextColor,
  createCommentPromisified,
  createPostDraftPromisified,
  deleteComment,
  disablePostCommentsPromisified,
  enablePostCommentsPromisified,
  fetchOembedPromisified,
  incrementCommentLikeCount,
  incrementPostLikeCount,
  openPostSettings,
  pinPostPromisified,
  publishPostPromisified,
  requestFileDownloadUrlPromisified,
  requestFileUploadUrlPromisified,
  saveAndPublishPostPromisified,
  savePostDraftPromisified,
  submitPostForModerationPromisified,
  subscribePromisified,
  unpinPostPromisified,
  unsubscribePromisified,
  updateCommentPromisified,
  uploadImagePromisified,
} from './lazy-actions';
import { type PlatformApi } from './platform-api';

export function initializeActions({
  platformApi,
  store,
  fedopsLogger,
}: {
  platformApi: PlatformApi;
  store: Store;
  fedopsLogger: FlowAPI['fedops'];
}) {
  return {
    interactionStarted: (interaction: string) => fedopsLogger.interactionStarted(interaction),
    interactionEnded: (interaction: string) => fedopsLogger.interactionEnded(interaction),

    navigateToSection: (section: string) =>
      platformApi.site
        .getPublicAPI(SANTA_MEMBERS_APP_ID)
        .then((api) => api.navigateToSection(section)),
    navigateToMember: (memberId: string) =>
      platformApi.site
        .getPublicAPI(SANTA_MEMBERS_APP_ID)
        .then((api) => api.navigateToMember(memberId)),
    logout: () => platformApi.user.logout(),
    navigateToProfile: (
      memberId: string | null | undefined,
      memberSlug: string | null | undefined,
    ) => {
      platformApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then((api) => {
        api.navigateToMember({ memberId, memberSlug }).catch(console.error);
      });
    },
    navigateToMyDrafts: (siteMemberIdOrSlug: string) => {
      platformApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then((api) => {
        api
          .navigateToSection({
            appDefinitionId: BLOG_APP_ID,
            sectionId: 'member-drafts-page',
            widgetId: '14f260e4-ea13-f861-b0ba-4577df99b961',
            memberId: siteMemberIdOrSlug,
          })
          .catch(console.error);
      });
    },
    navigateToProfileSettings: () => {
      platformApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then((api) => {
        const user = getCurrentUser(store.getState());
        api
          .navigateToSection({
            appDefinitionId: '14f25dc5-6af3-5420-9568-f9c5ed98c9b1',
            sectionId: 'settings',
            widgetId: '14f25dd2-f9b0-edc2-f38e-eded5da094aa',
            memberId: user.siteMemberId,
          })
          .catch(console.error);
      });
    },
    navigateToNotifications: () => {
      platformApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then((api) => {
        api
          .navigateToSection({
            appDefinitionId: '14f25924-5664-31b2-9568-f9c5ed98c9b1',
            sectionId: 'notifications',
            widgetId: '14f2595a-a352-3ff1-9b3c-4d21861fe58f',
          })
          .catch(console.error);
      });
    },
    navigateToMyPosts: ({ tab }: { tab: string }) => {
      platformApi.site.getPublicAPI(SANTA_MEMBERS_APP_ID).then((api) => {
        const user = getCurrentUser(store.getState());
        api
          .navigateToSection({
            appDefinitionId: BLOG_APP_ID,
            sectionId: 'My Posts',
            widgetId: 'e5a2773b-0e6b-4cbb-a012-3b4a69e92046',
            memberId: user.siteMemberId,
            tpaInnerRoute: `?tab=${tab}`,
          })
          .catch(console.error);
      });
    },
    navigateToPaidPlans: async (
      postId: string,
      planIds: string[] = [],
      returnUrl = '',
      titleText = '',
      contentText = '',
      buttonText = '',
    ) => {
      const api = await getPricingPlansPublicAPI(platformApi as unknown as IWixAPI);
      const content = omitBy({ title: titleText, message: contentText, cta: buttonText }, isNil);

      api.navigateToPackagePicker({
        planIds: planIds ? planIds : [],
        checkout: {
          thankYouPage: {
            content: !isEmpty(content) ? content : undefined,
            navigation: returnUrl
              ? {
                  type: 'section',
                  options: {
                    sectionId: BLOG_POST_PAGE_SECTION_ID,
                    appDefinitionId: BLOG_APP_ID,
                    state: returnUrl,
                  },
                }
              : undefined,
          },
        },
        biOptions: {
          referralInfo: REFERRAL_BLOG_POST_PAYWALL,
          referralId: postId,
        },
      });
    },
    renderSEOTags: (data: SeoTagsPayload) => platformApi.seo.renderSEOTags(data),
    ...bindActionCreators(
      {
        ...modalActions,
        ...messageActions,
        actionsClosed,
        actionsOpened,
        addTextColor,
        buttonClicked: (data) =>
          buttonClicked({ name: BUTTON_ACTION_CLICK, type: 'post', ...data }),
        categoryLabelClicked,
        deleteComment,
        emitBlogOpened,
        fastFormSubmitCallback,
        fileDownloaded: biFileDownloaded,
        fileUploaded: biFileUploaded,
        goBack: () => console.log('goBack'), // TODO: fix or remove
        gotoCreatePost: () => navigateWithinBlog('/create-post'),
        goToEditPage: (id) =>
          navigateToCustomPostUrl(getCustomPostEditUrl(store.getState(), id), `/${id}/edit`),
        goToFeed: () => navigateWithinBlog('/'),
        headerSearchClosed,
        headerSearchOpened,
        incrementCommentLikeCount,
        incrementPostLikeCount,
        navigateInPreview,
        navigateProGalleryWithinPostPage,
        navigateToCustomPostUrl,
        navigateToSearch,
        navigateToSectionInPreview,
        navigateWithinBlog,
        navigateWithinPostPage,
        openPostSettings,
        pageOpened,
        paginationClearCollection,
        postPageMoreButtonClicked,
        postPageMoreButtonOptionClicked,
        postPageSocialShareClicked,
        requestLogin,
        searchSubmitted,
        setIsLoaded,
        setIsLoading,
        showHeaderLogin,
        showJoinCommunityPopup,
        trackEvent,
      },
      store.dispatch,
    ),
  };
}

export function initializePromisifiedActions(
  {
    store,
  }: {
    store: Store;
  },
  actions = {},
) {
  return bindActionCreators(
    {
      createCommentPromisified,
      disablePostCommentsPromisified,
      duplicatePostPromisified: createPostDraftPromisified,
      enablePostCommentsPromisified,
      fetchArchivePostsWithAdapterPromisified,
      fetchCategoryPostsWithAdapterPromisified,
      fetchFeedPostsWithAdapterPromisified,
      fetchHashtagPostsWithAdapterPromisified,
      fetchMentionsPromisified,
      fetchOembedPromisified,
      fetchTagPostsWithAdapterPromisified,
      pinPostPromisified,
      publishPostPromisified,
      requestFileDownloadUrlPromisified,
      requestFileUploadUrlPromisified,
      requestLoginPromisified,
      saveAndPublishPostPromisified,
      savePostDraftPromisified,
      submitPostForModerationPromisified,
      subscribePromisified,
      unpinPostPromisified,
      unsubscribePromisified,
      updateCommentPromisified,
      uploadImagePromisified,
      fetchPostMetadata,
      ...actions,
    },
    store.dispatch,
  );
}

export async function initializeStoreBaseData({
  platformApi,
  store,
  language,
  platformAPIs,
  config,
  bundleName,
  translationsName,
  preFetch = () => Promise.resolve(),
  allowPreviewInstance = false,
  appParams,
  route,
}: {
  platformApi: PlatformApi;
  store: Store;
  language: string;
  config: ControllerConfig['config'];
  platformAPIs: ControllerConfig['platformAPIs'];
  appParams: ControllerConfig['appParams'];
  bundleName: string;
  translationsName: string;
  preFetch?: () => Promise<void>;
  allowPreviewInstance?: boolean;
  route: string;
}) {
  store.dispatch(initializeInstanceValues(getInstance(platformApi)()));
  store.dispatch(setLocale(language));
  store.dispatch(setQueryLocaleAction(platformApi, appParams));

  const viewMode = platformApi.window.viewMode.toLowerCase();
  const state = store.getState();
  const instanceId = getInstanceId(state);
  const loginInfo = resolveUser(platformApi, allowPreviewInstance);

  const commonActions = [
    store.dispatch(setAppSettings({ style: config.style.styleParams })),
    store.dispatch(
      waitForAction(
        [SET_APP_SETTINGS],
        () =>
          setBasicParams({
            viewMode,
            language,
            biPageNumber: platformAPIs.bi?.pageNumber,
          }),
        true,
      ),
    ),
    store.dispatch(fetchTopology(instanceId)),
    store.dispatch(initializeMembersNavigation()),
    store.dispatch(setCommunitiesContext()),
    store.dispatch(setEnableLinkfify(instanceId)),
    store.dispatch(setCustomRoutes()),
    store.dispatch(
      waitForAction(
        [FETCH_BLOG_DATA_SUCCESS, FETCH_TOPOLOGY_SUCCESS, SET_INSTANCE_VALUES],
        () => setAppConfig({ bundleName }),
        true,
      ),
    ),
  ];

  if (isFeedAdapterEnabled(route)) {
    return Promise.all([
      ...commonActions,
      preFetch(),
      store.dispatch(initializeInstanceValues(loginInfo.instance)),
    ]);
  }

  if (isPostPageAdapterEnabled(route)) {
    return Promise.all([
      ...commonActions,
      store
        .dispatch(
          fetchTPASettings({
            language: platformApi.window.multilingual.isEnabled
              ? getQueryLocale(state) || language
              : undefined,
            shouldUseFallbackOnError: true,
          }),
        )
        .then(() =>
          store.dispatch(
            fetchAppDataSuccess({
              ampEnabled: store.getState().tpaSettings.settings.ampEnabled,
            }),
          ),
        ),
      initUserDependentStoreBaseData({
        store,
        platformApi,
        allowPreviewInstance,
      }),
      preFetch(),
      store.dispatch(fetchCategories()),
    ]);
  }

  return Promise.all([
    ...commonActions,
    store
      .dispatch(
        fetchTPASettings({
          language: platformApi.window.multilingual.isEnabled
            ? getQueryLocale(state) || language
            : undefined,
          shouldUseFallbackOnError: true,
        }),
      )
      .then(() =>
        store.dispatch(
          fetchAppDataSuccess({
            ampEnabled: store.getState().tpaSettings.settings.ampEnabled,
          }),
        ),
      ),
    initUserDependentStoreBaseData({
      store,
      platformApi,
      allowPreviewInstance,
    }),
    preFetch(),
    store.dispatch(fetchCategories()),
    store.dispatch(fetchTranslations(language, translationsName)),
  ]);
}

export async function initUserDependentStoreBaseData({
  store,
  platformApi,
  allowPreviewInstance,
}: {
  store: Store;
  platformApi: PlatformApi;
  allowPreviewInstance?: boolean;
}) {
  const user = resolveUser(platformApi, allowPreviewInstance);
  store.dispatch(initializeInstanceValues(user.instance));

  // Workaround: user.loggedIn false is false when rendered OOI in Editor
  const siteMemberIdInEditor =
    platformApi.window.viewMode === 'Editor' || platformApi.window.viewMode === 'Preview'
      ? getSiteMemberId(store.getState())
      : undefined;

  if (!user.loggedIn && !siteMemberIdInEditor) {
    return Promise.resolve();
  }

  return store
    .dispatch(fetchCurrentUser(user.instance))
    .then(() => store.dispatch(setUser(getCurrentUser(store.getState()))))
    .catch(() => {});
}

export function refreshDataOnLogin({
  platformApi,
  store,
  router,
}: {
  platformApi: PlatformApi;
  store: Store;
  router?: Router;
}) {
  platformApi.user.onLogin(() =>
    setTimeout(async () => {
      await initUserDependentStoreBaseData({ store, platformApi });

      if (router) {
        const pathname = getCurrentMatchEncodedPathname(store.getState());
        doRouting({ store, router, pathname });
      }
    }),
  );
}
