export const ADD_ERROR_STATE = 'ADD_ERROR_STATE';
export const ADD_DEBUG_STATE = 'ADD_DEBUG_STATE';

export function addErrorState(error) {
  let errorString = '';
  if (typeof error !== 'string') {
    try {
      errorString = error.stack ? error.stack : error.toString();
    } catch (e) {}
  } else {
    errorString = error;
  }

  return {
    type: ADD_ERROR_STATE,
    payload: errorString,
  };
}

export function addDebugState(data) {
  return {
    type: ADD_DEBUG_STATE,
    payload: data,
  };
}
