import { type IHttpClient } from '@wix/yoshi-flow-editor';
import {
  getPostFeedPage,
  getPostFeedMetadataPage,
} from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/http';
import { FeedType } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/types';
import type { Post } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-page/types';
import {
  BLOG_HEADER_TOTAL_RESULTS,
  EXPERIMENTS,
  getWixDataCategoryId,
  getWixDataTagId,
  handleAggregatorResponseWithHeaders,
  isExperimentEnabled,
  postListWidgetStyleParams as params,
} from '@wix/communities-blog-client-common';
import { WarmupDataHandler } from '@app/common/warmup-data-handler';
import setPosts from '../../common/actions/set-posts';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';
import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { getQueryLocale } from '../../common/selectors/locale-selectors';
import { getDemoPosts } from '../../common/services/demo-posts';
import getEnvironment from '../../common/services/get-environment';
import { getTotalResults } from '../../common/services/pagination';
import { normalizePostV3 } from '../../common/services/post-utils';
import { fetchCategoriesSuccess } from '../../common/store/categories/fetch-categories';
import { getInstanceId } from '../../common/store/instance-values/instance-values-selectors';
import { setIsLoading } from '../../common/store/is-loading/is-loading-actions';
import { setPostCount } from '../../common/store/post-count/set-posts-count';
import { fetchTopology } from '../../common/store/topology/topology-actions';
import { fetchTranslationsSuccess } from '../../common/store/translations/translations-actions';
import { getPostListWidgetPageSize } from '../selectors/post-list-widget-page-size';
import { type PostListWidgetThunkAction } from '../types/post-list-widget-thunk-args';

type FetchPostListFrontendAdapterParams = {
  page?: number;
  httpClient: IHttpClient;
  includeInitialPageData: boolean;
  featuredOnly: boolean;
  categoryId: string;
  tagId: string;
  language: string;
  pageSize: number;
};

const fetchPostListFrontendAdapter = async ({
  page = 1,
  httpClient,
  includeInitialPageData,
  featuredOnly = false,
  categoryId,
  tagId,
  language,
  pageSize,
}: FetchPostListFrontendAdapterParams) => {
  const [response, metadataResponse] = await Promise.all([
    httpClient.request(
      getPostFeedPage({
        languageCode: language,
        page,
        pageSize,
        includeInitialPageData,
        type: FeedType.POST_LIST_WIDGET,
        postListWidgetOptions: {
          featuredOnly,
          categoryId,
          tagId,
        },
        translationsName: 'post-list-widget',
      }),
    ),
    httpClient.request(
      getPostFeedMetadataPage({
        type: FeedType.POST_LIST_WIDGET,
        page,
        pageSize,
        languageCode: language,
        postListWidgetOptions: {
          featuredOnly,
          categoryId,
          tagId,
        },
      }),
    ),
  ]);

  return { response, metadataResponse };
};

const handlePostListPostsResponse =
  (postsResponse: any, { page = 1 } = {}): PostListWidgetThunkAction =>
  (dispatch, getState, { platformApi, httpClient, aggregatorRequest }) => {
    dispatch(setIsLoading('postListPosts', undefined, true));

    return dispatch(handleAggregatorResponseWithHeaders(postsResponse))
      .then(async ({ body, headers }) => {
        if (!body.length && getEnvironment(platformApi).isEditorSegment) {
          const state = getState();
          const fake = await getDemoPosts({
            httpClient,
            aggregatorRequest,
            getState,
            dispatch,
            platformApi,
            query: {
              page,
              pageSize: getPostListWidgetPageSize(state),
              featuredOnly: getAppSettingsValue({
                state,
                key: `style.booleans.${params.postListWidgetIsFeatured.key}`,
              }),
              categoryId: getWixDataCategoryId(state),
              tagId: getWixDataTagId(state),
              language: getQueryLocale(state),
            },
          });

          body = fake.posts;
          headers = fake.headers as any;
        }

        dispatch(setPosts(body));
        dispatch(setPostCount(getTotalResults(headers)));
      })
      .then(() => dispatch(setIsLoading('postListPosts', undefined, false)))
      .catch(() => dispatch(setIsLoading('postListPosts', undefined, false)));
  };

export const fetchInitialData =
  (): PostListWidgetThunkAction =>
  async (dispatch, getState, { compId, flowAPI, platformApi, httpClient }) => {
    const state = getState();
    const instanceId = getInstanceId(state);
    const featuredOnly = getAppSettingsValue({
      state,
      key: `style.booleans.${params.postListWidgetIsFeatured.key}`,
    });
    const categoryId = getWixDataCategoryId(state) || undefined;
    const tagId = getWixDataTagId(state) || undefined;
    const language = getQueryLocale(state) ?? platformApi.site.language;
    const pageSize = getPostListWidgetPageSize(state);

    const data = await new WarmupDataHandler({
      platformApi,
      warmupDataKey: `post-list-${compId}-${featuredOnly}-${categoryId}-${tagId}-${language}-${pageSize}`,
      isSSR: flowAPI.environment.isSSR,
      isWarmupDataExperimentEnabled: isExperimentEnabled(
        state,
        EXPERIMENTS.USE_WARMUP_STATE_IN_POST_LIST,
      ),
      loaderFn: () =>
        fetchPostListFrontendAdapter({
          page: 1,
          httpClient,
          includeInitialPageData: true,
          categoryId,
          tagId,
          language,
          pageSize,
          featuredOnly,
        }),
    }).load();

    const postFeedPage = data.response.data.postFeedPage;
    const postFeedMetadataPage = data.metadataResponse.data.postFeedMetadataPage;

    dispatch(fetchTranslationsSuccess(postFeedPage?.translations));

    if (postFeedPage?.categories) {
      dispatch(fetchCategoriesSuccess(postFeedPage?.categories));
    }

    dispatch(fetchTopology(instanceId));
    await dispatch(
      handlePostListPostsResponse({
        status: 200,
        body: postFeedPage?.posts?.posts.map((post: Post) => {
          const metrics = postFeedMetadataPage?.postMetrics?.[post.id!];

          return normalizePostV3({
            ...post,
            metrics,
          });
        }),
        headers: {
          [BLOG_HEADER_TOTAL_RESULTS]: postFeedPage?.posts?.pagingMetaData?.total,
        },
      }),
    );
  };

export const fetchPostListPosts =
  (page?: number): PostListWidgetThunkAction =>
  async (dispatch, getState, { aggregatorRequest, httpClient, platformApi }) => {
    const state = getState();

    const featuredOnly = getAppSettingsValue({
      state,
      key: `style.booleans.${params.postListWidgetIsFeatured.key}`,
    });
    const categoryId = getWixDataCategoryId(state) || undefined;
    const tagId = getWixDataTagId(state) || undefined;
    const language = getQueryLocale(state) ?? platformApi.site.language;
    const pageSize = getPostListWidgetPageSize(state);

    const data = await fetchPostListFrontendAdapter({
      page,
      httpClient,
      includeInitialPageData: false,
      featuredOnly,
      categoryId,
      tagId,
      language,
      pageSize,
    });

    const postFeedPage = data.response.data.postFeedPage;
    const postFeedMetadataPage = data.metadataResponse.data.postFeedMetadataPage;

    await dispatch(
      handlePostListPostsResponse(
        {
          status: 200,
          body: postFeedPage?.posts?.posts?.map((post: Post) =>
            normalizePostV3({
              ...post,
              metrics: postFeedMetadataPage?.postMetrics?.[post.id!],
            }),
          ),
          headers: {
            [BLOG_HEADER_TOTAL_RESULTS]: postFeedPage?.posts?.pagingMetaData?.total,
          },
        },
        { page },
      ),
    );
  };

export const fetchPostListPostsPromisified = createPromisifiedAction(
  fetchPostListPosts,
  () => null,
  (response) => response.status,
);
