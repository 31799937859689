import { SET_MA_NAVIGATION } from './ma-navigation-actions';

const defaultState = {
  profileLinkTemplate: '',
  settingsLink: '',
  notificationsLink: '',
};

export function maNavigation(state = defaultState, action) {
  switch (action.type) {
    case SET_MA_NAVIGATION:
      return action.payload;
    default:
      return state;
  }
}
