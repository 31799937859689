import { SET_QUERY_LOCALE, SET_LOCALE } from './locale-actions';

export default function locale(state = { current: 'en', query: null }, action) {
  switch (action.type) {
    case SET_LOCALE:
      return { ...state, current: action.payload };
    case SET_QUERY_LOCALE:
      return { ...state, query: action.payload };
    default:
      return state;
  }
}
