import { createAction } from '@wix/communities-blog-client-common';

export const FETCH_ARCHIVE_REQUEST = 'archive/FETCH_REQUEST';
export const FETCH_ARCHIVE_SUCCESS = 'archive/FETCH_SUCCESS';
export const FETCH_ARCHIVE_FAILURE = 'archive/FETCH_FAILURE';

export const fetchArchiveRequest = createAction(FETCH_ARCHIVE_REQUEST);
export const fetchArchiveFailure = createAction(FETCH_ARCHIVE_FAILURE);
export const fetchArchiveSuccess = createAction(
  FETCH_ARCHIVE_SUCCESS,
  (payload, meta) => payload,
  (payload, meta) => meta,
);
