import { type PlatformQuery } from '@wix/ambassador-blog-v3-tag/types';

type FetchDataInChunks<T> = {
  filter: PlatformQuery['filter'];
  sort: PlatformQuery['sort'];
  limit: number;
  chunkSize?: number;
  dataFn: (
    platformQuery: PlatformQuery,
  ) => Promise<{ nextCursor: string | null | undefined; items: T[] }>;
};

/**
 * Calls specified dataFn with prepared PlatformQuery parameters to load data in chunks.
 *
 * Useful if API has a limited amount of items per request. Data from all requests is concatenated.
 **/
export async function fetchDataInChunksForNile<T>(params: FetchDataInChunks<T>): Promise<T[]> {
  const results: T[] = [];
  const MAX_PAGE_SIZE_ON_NILE = 100;

  let nextCursor: string | null | undefined;

  do {
    const remainingCount = params.limit - results.length;
    const pageSize = Math.min(params.chunkSize ?? MAX_PAGE_SIZE_ON_NILE, remainingCount);

    const response = await params.dataFn(
      nextCursor
        ? { cursorPaging: { cursor: nextCursor, limit: pageSize } }
        : {
            filter: params.filter,
            sort: params.sort,
            paging: { limit: pageSize, offset: 0 },
          },
    );

    results.push(...response.items);
    nextCursor = response.nextCursor;
  } while (nextCursor && results.length < params.limit);

  return results;
}
