import { type PlatformApi } from '@app/external/common/controller/platform-api';

type HandlerFn<T> = (payload: T) => Promise<void> | void;

/**
 * A store that fetches data on location/user change and notifies subscribers.
 */
export class LocationAwareStore<Payload> {
  #promiseRef: Promise<Payload> | undefined;
  #handlers: HandlerFn<Payload>[] = [];

  constructor(
    private readonly context: {
      platformApi: PlatformApi;
      dataFn: () => Promise<Payload>;
    },
  ) {
    this.updatePromiseReferenceOnLocationChange();
  }

  subscribe = async (handler: HandlerFn<Payload>) => {
    this.#promiseRef ??= this.context.dataFn();

    const model = await this.#promiseRef;
    await handler(model);
    this.#handlers.push(handler);
  };

  private updatePromiseReferenceOnLocationChange() {
    const handleLocationChange = async () => {
      if (this.#handlers.length === 0) {
        return;
      }

      this.#promiseRef = this.context.dataFn();

      const modelForNewLocation = await this.#promiseRef;

      for (const handler of this.#handlers) {
        handler(modelForNewLocation);
      }
    };

    this.context.platformApi.user.onLogin(handleLocationChange);
    this.context.platformApi.location.onChange(handleLocationChange);
  }
}
