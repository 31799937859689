import { SET_BASIC_PARAMS, SET_INSTANCE } from './basic-params-actions';

export function basicParams(state = { viewMode: 'site' }, { type, payload }) {
  switch (type) {
    case SET_BASIC_PARAMS:
      return payload;
    case SET_INSTANCE:
      return { ...state, instance: payload };
    default:
      return state;
  }
}
