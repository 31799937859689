import { BI_ACTIVE_TAB_CHANGED } from '../actions/bi';

const handler = ({ payload }) => {
  return {
    evid: 2102,
    eventMeta: {
      description: 'active tab changed',
    },
    ...payload,
  };
};

export const uouActiveTabChangedEvent = { [BI_ACTIVE_TAB_CHANGED]: handler };
