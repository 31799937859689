import { isRichContent } from '@wix/ricos';
import { extractMediaUrls } from '../../blocks-widget/helpers/extract-media-urls';

export const extractMediaUrlsFromRichContent = (
  content: Record<string, any>,
): string[] | undefined => {
  if (isRichContent(content)) {
    return extractMediaUrls(content.nodes);
  }
};
