import { get } from 'lodash';
import type { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { BLOG_SECTION_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { createPerformanceTracker, getSettingsEvent } from '@wix/communities-blog-client-common';
import { type WithReduxStoreOwnProps } from '../../common/components/runtime-context/with-redux-store';
import {
  createControllerId,
  createLogger,
  doRouting,
  getSectionPathname,
  handleLocationChange,
} from '../../common/controller/helpers';
import { initLazyActions } from '../../common/controller/lazy-actions';
import { getPlatformApi } from '../../common/controller/platform-api';
import { setRouterMatch } from '../../common/router';
import { createMonitoring } from '../../common/services/create-monitoring';
import { isInCategory } from '../../common/services/detect-route';
import { getCurrentSiteLanguage } from '../../common/services/get-current-site-language';
import getEnvironment from '../../common/services/get-environment';
import { isRtlLanguage } from '../../common/services/is-rtl-language';
import listenToInstanceChange from '../../common/services/listen-to-instance-change';
import { simulateControllerError } from '../../common/services/simulate-error';
import { subscribeToChange } from '../../common/services/state-optimizer';
import { getInitialStateVersions } from '../../common/services/state-optimizer/change-detector';
import { setAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { fetchSiteProperties } from '../../common/store/site-properties/site-properties-actions';
import { type AppStore } from '../../common/types';
import { getSectionUrl } from '../services/get-section-url';
import { createReduxStore } from './create-redux-store';
import { createRouter } from './create-router';
import {
  initializeActions,
  initializePromisifiedActions,
  initializeStoreBaseData,
  refreshDataOnLogin,
} from './init-actions';

const isProduction = process.env.NODE_ENV === 'production';

type RootProps = WithReduxStoreOwnProps & {
  isRTL: boolean;
  fitToContentHeight: boolean;
};

export const createFeedPageController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}) => {
  const platformApi = await getPlatformApi(controllerConfig.platformApiProvider);
  const initialSiteTitle = platformApi.seo.title;
  const { isEditor, isPreview, isSSR, isDebug } = getEnvironment(platformApi);

  const { sentry, fedopsLogger } = createMonitoring(flowAPI);

  const language = getCurrentSiteLanguage(platformApi, flowAPI);

  const bundleName = 'blog' as const;

  const log = createLogger(isDebug, isProduction);
  const perf = createPerformanceTracker(bundleName, { isDebug, isSSR });

  log('createFeedPageController', {
    appParams: controllerConfig.appParams,
    platformApi,
    isSSR,
    language,
  });

  let store: AppStore;

  const pageReady = async () => {
    const pageReadyMarker = perf.trackStart(`${new Date().toISOString().slice(11)} pageReady`);
    log('createFeedPageController.pageReady -> start');

    const sectionUrl = await getSectionUrl({
      platformApi,
      instanceId: controllerConfig.appParams.instanceId,
      sectionId: BLOG_SECTION_ID,
    });

    simulateControllerError(platformApi, 'feed-page.pageReady');

    initLazyActions({ isSSR });

    let marker = perf.trackStart('createReduxStore', pageReadyMarker);
    store = createReduxStore({
      appParams: controllerConfig.appParams,
      compId: controllerConfig.compId,
      platformAPIs: controllerConfig.platformAPIs,
      platformApi,
      fedopsLogger,
      getRouter: () => router,
      isSSR,
      sentry,
      isEditor,
      isPreview,
      language,
      bundleName,
      flowAPI,
    });
    perf.trackEnd(marker);

    marker = perf.trackStart('initializeActions', pageReadyMarker);
    const actions = initializeActions({
      platformApi,
      store,
      fedopsLogger,
    });
    const actionsPromisified = initializePromisifiedActions({
      store,
    });
    perf.trackEnd(marker);

    marker = perf.trackStart('createRouter', pageReadyMarker);
    const router = createRouter({
      store,
      controllerConfig,
      platformApi,
      flowAPI,
      initialSiteTitle,
    });
    perf.trackEnd(marker);

    const pathname = getSectionPathname({
      platformApi,
      sectionUrl,
    });
    const matchedRoute = await router.matchPath({ pathname });
    const route = get(matchedRoute, 'route');
    const preFetch = () =>
      Promise.all([
        router.preFetch(pathname),
        isInCategory(route) ? store.dispatch(fetchSiteProperties()) : Promise.resolve(),
      ]);

    await perf.trackPromise(
      'initializeStoreBaseData',
      () =>
        initializeStoreBaseData({
          platformApi,
          store,
          language,
          platformAPIs: controllerConfig.platformAPIs,
          config: controllerConfig.config,
          bundleName,
          preFetch: preFetch as any,
          appParams: controllerConfig.appParams,
          translationsName: 'main',
          route,
        }),
      pageReadyMarker,
    );
    listenToInstanceChange(platformApi, controllerConfig.appParams, store);

    handleLocationChange({
      platformApi,
      store,
      log,
      bundleName,
      router,
      sectionUrl,
    });

    await perf.trackPromise(
      'doRouting',
      () =>
        doRouting({
          store,
          router,
          pathname: getSectionPathname({
            platformApi,
            sectionUrl,
          }),
          isInitialLoad: true,
        }),
      pageReadyMarker,
    );

    log('createFeedPageController.pageReady -> done');

    const state = store.getState();

    // after initial routing is done, we subscribe to get routing change as fast as route changes, without waiting for handler to resolve
    router.onMatch((match: any) => store.dispatch(setRouterMatch(match)));

    const stateVersions = getInitialStateVersions(state);
    const controllerId = createControllerId();

    controllerConfig.setProps({
      state,
      stateVersions,
      actions,
      actionsPromisified,
      isSSR,
      isRTL: isRtlLanguage(language),
      controllerId,
      fitToContentHeight: true,
    } satisfies RootProps);

    log('state', { state });

    refreshDataOnLogin({ platformApi, store, router });
    subscribeToChange(store, stateVersions, controllerConfig.setProps, controllerId);
    perf.trackEnd(pageReadyMarker);
  };

  return {
    pageReady,
    exports: () => {},
    updateConfig: ($w, updatedConfig) => {
      const styleParams = updatedConfig.style.styleParams;
      const publicData = updatedConfig.publicData;

      if (!store) {
        return;
      }

      store.dispatch(setAppSettings({ style: styleParams }));

      const settingsEvent = getSettingsEvent(publicData);
      if (settingsEvent) {
        store.dispatch(settingsEvent);
      }
    },
  };
};
