import { type ThunkAction } from 'redux-thunk';
import type { HttpClient } from '@wix/http-client';
import { updateDraftPost } from '@wix/ambassador-blog-v3-draft/http';
import { createAction, type RequestClient } from '@wix/communities-blog-client-common';
import { normalizePost } from '../services/post-utils';
import { getCategoryIds } from '../store/categories/categories-selectors';
import { type AppState } from '../types';

export const UPDATE_POST_SUCCESS = 'post/DRAFT_UPDATE_SUCCESS';

const updatePostRequest = createAction('post/DRAFT_UPDATE_REQUEST');
const updatePostSuccess = createAction(UPDATE_POST_SUCCESS);
const updatePostFailure = createAction('post/DRAFT_UPDATE_FAILURE');

type UpdatePostThunk = ThunkAction<
  Promise<any>,
  AppState,
  { request: RequestClient; httpClient: HttpClient },
  any
>;

const getLatestSlug = (slugs: string[] | undefined) => {
  if (!slugs) {
    throw new Error('Slugs are undefined');
  }
  return slugs[slugs.length - 1];
};

export const updatePost = (post: any): UpdatePostThunk => {
  return async (dispatch, getState, { request, httpClient }) => {
    dispatch(updatePostRequest());

    const state = getState();

    const updatePostPromise = httpClient
      .request(updateDraftPost({ draftPost: post }))
      .then(({ data }) => {
        const draftPost = data?.draftPost;

        if (!draftPost) {
          throw new Error('Post is undefined');
        }

        return {
          ...draftPost,
          slug: getLatestSlug(draftPost.slugs),
        };
      });

    try {
      const updatedPost = await updatePostPromise;
      dispatch(
        updatePostSuccess(
          normalizePost({
            state,
            post: updatedPost,
            blogCategoryIds: getCategoryIds(getState()),
          }),
        ),
      );
    } catch {
      dispatch(updatePostFailure());
    }

    return updatePostPromise;
  };
};
