import { type ViewerScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { BLOG_POST_PAGE_SECTION_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
import { WarmupDataHandler } from '@app/common/warmup-data-handler';
import { type PlatformApi } from '@app/external/common/controller/platform-api';
import { PostPageRenderModel } from '../external/blocks-widget/post-page-render-model';
import { getSectionPathname } from '../external/common/controller/helpers';
import { Router } from '../external/common/router';
import { resolvePostSlug } from '../external/common/services/slug';
import {
  ROUTE_404,
  ROUTE_POST,
  ROUTE_POST_ADVANCED_SLUG,
  ROUTE_PREVIEW_POST,
} from '../external/post-page/constants/routes';
import { customRouteHandler } from '../external/post-page/controller/route-handlers/custom-route-handler';
import { getSectionUrl } from '../external/post-page/services/get-section-url';
import { fetchMetadata } from './metadata';
import { getPostPageData } from './post';

interface FetchInitialDataParams {
  platformApi: PlatformApi;
  instanceId: string;
  flowAPI: ViewerScriptFlowAPI;
}

export const fetchInitialData = async ({
  platformApi,
  instanceId,
  flowAPI,
}: FetchInitialDataParams): Promise<PostPageRenderModel> => {
  const readingSessionId = `${parseInt(`${Math.random() * 10000000}`, 10)}-${Date.now()}`;
  const { postId, instance } = await extractPostIdAndInstanceFromCurrentUrl(
    platformApi,
    instanceId,
  );

  const initialData = await new WarmupDataHandler({
    platformApi,
    warmupDataKey: `PostPageWarmupData-${postId}`,
    isSSR: flowAPI.environment.isSSR,
    isWarmupDataExperimentEnabled: flowAPI.essentials.experiments.enabled(
      'specs.wixBlog.UseWarmupStateInNewPostPage',
    ),
    loaderFn: async () => {
      const { post, categories, appData, translations } = await getPostPageData({
        platformApi,
        flowAPI,
        postId,
        instance,
      });

      const metadata = await fetchMetadata({
        platformApi,
        flowAPI,
        postSlug: post.slug,
        postId: post.id,
      });

      return {
        post,
        categories,
        appData,
        translations,
        metadata,
      };
    },
  }).load();

  return new PostPageRenderModel({
    post: initialData.post,
    tags: initialData.post.tags ?? [],
    metadata: {
      ...initialData.metadata,
      isLiked: Boolean(initialData.post.isLiked),
    },
    readingSessionId,
    categories: initialData.categories,
    appData: initialData.appData,
    instanceId,
    platformApi,
  });
};

const extractPostIdAndInstanceFromCurrentUrl = async (
  platformApi: PlatformApi,
  instanceId: string,
) => {
  const router = new Router();
  const pathname = getSectionPathname({
    platformApi,
    sectionUrl: await getSectionUrl({
      platformApi,
      instanceId,
      sectionId: BLOG_POST_PAGE_SECTION_ID,
    }),
  });

  router.add(ROUTE_PREVIEW_POST);
  router.add(ROUTE_POST);
  router.add(ROUTE_POST_ADVANCED_SLUG);
  router.addCustomRouteHandler(() => customRouteHandler(platformApi));
  router.fallback(ROUTE_404);

  const matchPathResult = await router.matchPath({ pathname });

  if ('postId' in matchPathResult.params && 'instance' in matchPathResult.params) {
    return matchPathResult.params;
  }

  const postId = resolvePostSlug(matchPathResult.params);

  return {
    postId,
    instance: undefined,
  };
};
