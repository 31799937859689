import { OPEN_MODAL, CLOSE_MODAL } from '../modal-actions';

export function resolvedModalList(state = [], { type, payload }) {
  switch (type) {
    case OPEN_MODAL: {
      return state.filter((t) => t !== payload.type);
    }
    case CLOSE_MODAL: {
      if (!payload.resolve) {
        return state;
      }
      return [...state, payload];
    }
    default:
      return state;
  }
}
