import { createAction } from '@wix/communities-blog-client-common';

export const ADD_TEXT_COLOR_REQUEST = 'rceSettings/ADD_TEXT_COLOR_REQUEST';
export const ADD_TEXT_COLOR_SUCCESS = 'rceSettings/ADD_TEXT_COLOR_SUCCESS';
const addTextColorRequest = createAction(ADD_TEXT_COLOR_REQUEST);
const addTextColorSuccess = createAction(ADD_TEXT_COLOR_SUCCESS);
const addTextColorFailure = createAction('rceSettings/ADD_TEXT_COLOR_FAILURE');

export const addTextColor =
  (color) =>
  (dispatch, getState, { request }) => {
    dispatch(addTextColorRequest({ color }));

    return request
      .post('/_api/rce-settings', { color })
      .then((rceSettings) => dispatch(addTextColorSuccess({ rceSettings })))
      .catch(() => dispatch(addTextColorFailure()));
  };

export const FETCH_RCE_SETTINGS_SUCCESS = 'rceSettings/FETCH_SUCCESS';
const fetchRceSettingsRequest = createAction('rceSettings/FETCH_REQUEST');
const fetchRceSettingsSuccess = createAction(FETCH_RCE_SETTINGS_SUCCESS);
const fetchRceSettingsFailure = createAction('rceSettings/FETCH_FAILURE');

export const fetchRceSettings =
  () =>
  (dispatch, getState, { request }) => {
    dispatch(fetchRceSettingsRequest());

    return request('/_api/rce-settings')
      .then((rceSettings) => dispatch(fetchRceSettingsSuccess({ rceSettings })))
      .catch(() => dispatch(fetchRceSettingsFailure()));
  };
