import type { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { createControllerId, createLogger } from '../../common/controller/helpers';
import { initLazyActions } from '../../common/controller/lazy-actions';
import { getPlatformApi } from '../../common/controller/platform-api';
import { createMonitoring } from '../../common/services/create-monitoring';
import { getCurrentSiteLanguage } from '../../common/services/get-current-site-language';
import getEnvironment from '../../common/services/get-environment';
import { isRtlLanguage } from '../../common/services/is-rtl-language';
import listenToInstanceChange from '../../common/services/listen-to-instance-change';
import { simulateControllerError } from '../../common/services/simulate-error';
import { subscribeToChange } from '../../common/services/state-optimizer';
import { getInitialStateVersions } from '../../common/services/state-optimizer/change-detector';
import { type AppStore } from '../../common/types';
import { createReduxStore } from './create-redux-store';
import {
  initializeActions,
  initializePromisifiedActions,
  initializeStoreBaseData,
  refreshDataOnLogin,
} from './init-actions';

const isProduction = process.env.NODE_ENV === 'production';

export const createCategoryMenuController: CreateControllerFn = async ({
  controllerConfig: { appParams, compId, config, setProps, platformAPIs, platformApiProvider },
  flowAPI,
}) => {
  const platformApi = await getPlatformApi(platformApiProvider);
  const { isSSR, isDebug } = getEnvironment(platformApi);

  const { fedopsLogger } = createMonitoring(flowAPI);

  const language = getCurrentSiteLanguage(platformApi, flowAPI);

  const log = createLogger(isDebug, isProduction);

  log('createCategoryMenuController', {
    appParams,
    platformApi,
    isSSR,
    language,
  });

  let store: AppStore;

  const pageReady = async () => {
    log('createCategoryMenuController.pageReady -> start');

    simulateControllerError(platformApi, 'category-menu.pageReady');

    const bundleName = 'category-menu-widget';

    initLazyActions({ isSSR });

    store = createReduxStore({
      appParams,
      platformApi,
      compId,
      fedopsLogger,
      bundleName,
      flowAPI,
    });
    const actions = initializeActions({
      platformApi,
      store,
      fedopsLogger,
    });
    const actionsPromisified = initializePromisifiedActions({
      store,
    });

    await initializeStoreBaseData({
      platformApi,
      store,
      language,
      platformAPIs,
      config,
      bundleName,
      appParams,
      flowAPI,
    });
    listenToInstanceChange(platformApi, appParams, store);

    log('createCategoryMenuController.pageReady -> done');
    const state = store.getState();

    const stateVersions = getInitialStateVersions(state);
    const controllerId = createControllerId();

    setProps({
      state,
      stateVersions,
      actions,
      actionsPromisified,
      isSSR,
      isRTL: isRtlLanguage(language),
      controllerId,
      fitToContentHeight: true,
      publicData: config.publicData,
      stylesParams: config.style.styleParams,
    });

    refreshDataOnLogin({ platformApi, store, router: undefined });
    subscribeToChange(store, stateVersions, setProps, controllerId);
  };

  return {
    pageReady,
    exports: () => {},
  };
};
