import { combineReducers, applyMiddleware, createStore, type Store } from 'redux';
import thunk from 'redux-thunk';
import { type PlatformApi } from '@app/external/common/controller/platform-api';
import { type ControllerConfig, type FlowAPI } from '@app/external/common/types';
import createShowMessage from '../../common/messages/framework/create-show-message';
import { createMessageMiddleware } from '../../common/messages/framework/initialize-store';
import { postMetadataMiddleware } from '../../common/middleware/post-metadata-middleware';
import {
  createModalClosedMiddleware,
  createModalOpenedMiddleware,
} from '../../common/modals/framework/initialize-store';
import commonReducers from '../../common/reducers';
import createRequests from '../../common/services/create-requests';
import postCount from '../../common/store/post-count/post-count-reducer';
import messageResolvers from '../containers/messages/message-resolvers';
import { modalDataLoaderByType } from '../containers/modals/modal-data-loader-map-by-type';
import { modalResolverMapByType } from '../containers/modals/modal-resolver-map-by-type';
import activeTab from '../store/active-tab/active-tab-reducer';
import viewedUser from '../store/viewed-user/viewed-user-reducer';
import { type MyPostsWidgetThunkArgs } from '../types';

export function createReduxStore({
  appParams,
  platformApi,
  compId,
  fedopsLogger,
  isSSR,
  language,
  bundleName,
  flowAPI,
}: {
  appParams: ControllerConfig['appParams'];
  platformApi: PlatformApi;
  compId: ControllerConfig['compId'];
  fedopsLogger: FlowAPI['fedops'];
  isSSR: boolean;
  language: string;
  bundleName: string;
  flowAPI: FlowAPI;
}) {
  const p: {
    store?: Store;
  } = {};
  const getStore = () => p.store!;

  const { request, aggregatorRequest } = createRequests({
    platformApi,
    bundleName,
    getStore,
    flowAPI,
  });

  const middleware = [
    thunk.withExtraArgument({
      request,
      aggregatorRequest,
      platformApi,
      compId,
      appParams,
      fedopsLogger,
      httpClient: flowAPI.httpClient,
    } satisfies MyPostsWidgetThunkArgs),
    postMetadataMiddleware({ isSSR, language, localizePosts: true }),
    createModalClosedMiddleware({ modalResolverMapByType, createShowMessage }),
    createModalOpenedMiddleware({ modalDataLoaderByType }),
    createMessageMiddleware({ resolvers: messageResolvers }),
  ];

  const reducers = combineReducers({
    ...commonReducers,
    postCount,
    viewedUser,
    activeTab,
  });

  const initialState = { experiments: flowAPI.essentials.experiments.all() };

  p.store = createStore(reducers, initialState, applyMiddleware(...middleware));

  return p.store;
}
