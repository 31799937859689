import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'Post Counters',
  props: {
    isCommentsPluginInstalled: {
      type: 'boolean',
      description: 'Indicates if the comments plugin is installed',
      defaultValue: false,
    },
  },
  methods: {
    initialize: {
      description: 'Initializes the Post Counters widget',
      params: [
        {
          name: 'model',
          description: 'The model to initialize the widget',
        },
      ],
    },
  },
  events: {},
});
