import { get } from 'lodash';
import { pageOpened } from '../actions/page-opened';
import { isSSR } from '../store/basic-params/basic-params-selectors';
import { type AppStore } from '../types';
import { type PlatformApi } from './platform-api';

export const createNotFoundPageRouter =
  (store: AppStore, platformApi: PlatformApi, page: string, route: string) =>
  ({ pathname, prevMatches }: any) => {
    const isManualRedirect = pathname === route && get(prevMatches, 'length');
    isManualRedirect && (pathname = get(prevMatches, '[0].pathname'));

    !isSSR(store.getState()) && store.dispatch(pageOpened({ page, pathname }));

    platformApi.seo.setSeoStatusCode(404);
  };
