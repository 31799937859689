import { FETCH_TRANSLATIONS_SUCCESS } from './translations-actions';

export function translations(state = null, action) {
  switch (action.type) {
    case FETCH_TRANSLATIONS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
