import { SET_CUSTOM_ROUTES } from './custom-routes-actions';

export default function customRoutes(state = null, { type, payload }) {
  switch (type) {
    case SET_CUSTOM_ROUTES:
      return payload;

    default:
      return state;
  }
}
