import { getIsRelatedPostsEnabled } from '../../common/selectors/app-settings-selectors';
import { fetchProfileUrls } from '../../common/store/profile-urls/profile-urls-actions';
import type { AppStore, NormalizedPost } from '../../common/types';
import fetchRecentPosts from '../actions/fetch-recent-posts';
import fetchRelatedPosts from '../actions/fetch-related-posts';

interface FetchRecentPostsAndCommentsParams {
  store: AppStore;
  post: NormalizedPost;
}

export async function fetchRecentPostsAndComments({
  store,
  post,
}: FetchRecentPostsAndCommentsParams) {
  const state = store.getState();
  const isRelatedPostsEnabled = getIsRelatedPostsEnabled(state) !== false;

  const requests: Promise<unknown>[] = [
    store.dispatch(fetchProfileUrls()),
    store.dispatch(fetchRecentPosts(post)),
  ];

  if (isRelatedPostsEnabled && post.relatedPostIds?.length) {
    requests.push(store.dispatch(fetchRelatedPosts(post)));
  }

  await Promise.all(requests);
}
