import { get } from 'lodash';
import {
  VIEW_MODE_EDITOR,
  VIEW_MODE_PREVIEW,
  fetchWixData,
  fetchWixDataSuccess,
} from '@wix/communities-blog-client-common';
import Wix from '../services/wix-sdk-polyfill';

export const initWixData =
  ({ viewMode, dataKeys, config }) =>
  async (dispatch) => {
    const values = dataKeys.reduce((acc, dataKey) => {
      acc[dataKey] = get(config, ['publicData', 'COMPONENT', dataKey], null);

      return acc;
    }, {});

    return dispatch(fetchWixDataSuccess(values));
  };
