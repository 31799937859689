import { SHOW_HEADER_LOGIN } from './show-header-login';

const initialState = {
  showLogin: true,
};

export default function headerWidget(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_HEADER_LOGIN:
      return {
        showLogin: action.payload,
      };
    default:
      return state;
  }
}
