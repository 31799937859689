import { createAction } from '@wix/communities-blog-client-common';

export const SHOW_MESSAGE = 'message/SHOW';
export const HIDE_MESSAGE = 'message/HIDE';

export const showMessage = createAction(SHOW_MESSAGE, (type, props) => ({
  type,
  props,
}));
export const hideMessage = createAction(HIDE_MESSAGE);
