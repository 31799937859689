import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { type IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import { createNotFoundPageRouter } from '../../common/controller/create-router';
import type { PlatformApi } from '../../common/controller/platform-api';
import { Router } from '../../common/router';
import { FEED_PAGE_NOT_FOUND } from '../../common/services/detect-route';
import { type AppStore } from '../../common/types';
import {
  ROUTE_404,
  ROUTE_ARCHIVE,
  ROUTE_CATEGORIES,
  ROUTE_CATEGORY,
  ROUTE_HASHTAG,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_SEARCH,
  ROUTE_TAGS,
} from '../constants/routes';
import { createArchivePageRouter } from './create-routers/create-archive-page-router';
import { createCategoryPageRouter } from './create-routers/create-category-page-router';
import { createFeedPageRouter } from './create-routers/create-feed-page-router';
import { createHashtagPageRouter } from './create-routers/create-hashtag-page-router';
import { createSearchPageRouter } from './create-routers/create-search-page-router';
import { createTagPageRouter } from './create-routers/create-tag-page-router';

type Params = {
  store: AppStore;
  platformApi: PlatformApi;
  controllerConfig: IWidgetControllerConfig;
  flowAPI: ControllerFlowAPI;
  initialSiteTitle: string;
};

export const createRouter = ({
  store,
  platformApi,
  controllerConfig,
  flowAPI,
  initialSiteTitle,
}: Params) => {
  const router = new Router();

  router.add(ROUTE_TAGS, createTagPageRouter(store, platformApi), {
    preFetch: true,
  });

  router.add(ROUTE_CATEGORIES, createCategoryPageRouter({ store, platformApi, controllerConfig }), {
    preFetch: true,
  });

  router.add(ROUTE_CATEGORY, createCategoryPageRouter({ store, platformApi, controllerConfig }), {
    preFetch: true,
  });

  router.add(ROUTE_SEARCH, createSearchPageRouter(store, platformApi));

  router.add(ROUTE_HASHTAG, createHashtagPageRouter(store, platformApi), {
    preFetch: true,
  });

  router.add(ROUTE_ARCHIVE, createArchivePageRouter(store, platformApi), {
    preFetch: true,
  });

  router.add(
    ROUTE_HOME,
    createFeedPageRouter({
      store,
      platformApi,
      flowAPI,
      controllerConfig,
      initialSiteTitle,
    }),
    { preFetch: true },
  );

  router.add(
    ROUTE_404,
    createNotFoundPageRouter(store, platformApi, FEED_PAGE_NOT_FOUND, ROUTE_404),
  );

  router.add(ROUTE_LOGIN);

  router.fallback(ROUTE_404);

  return router;
};
