import { SET_INSTANCE_VALUES } from './instance-values-actions';

export function instanceValues(state = {}, action) {
  switch (action.type) {
    case SET_INSTANCE_VALUES:
      return action.payload;
    default:
      return state;
  }
}
