import { type PlatformApi } from '@app/external/common/controller/platform-api';

/**
 * Wraps IWixWindow.warmupData API by encoding values as base64 before saving them
 *
 * This allows to safely store text containing HTML, otherwise some
 * the untreated HTML could break JSON.parse on the warmupData object.
 * */
export class WarmupDataHandler<T> {
  private readonly isSSR: boolean;
  private readonly isWarmupDataExperimentEnabled: boolean;
  private readonly loaderFn: () => Promise<T>;
  private readonly platformApi: PlatformApi;
  private readonly warmupDataKey: string;

  constructor(config: {
    isSSR: boolean;
    isWarmupDataExperimentEnabled: boolean;
    loaderFn: () => Promise<T>;
    platformApi: PlatformApi;
    warmupDataKey: string;
  }) {
    this.isSSR = config.isSSR;
    this.isWarmupDataExperimentEnabled = config.isWarmupDataExperimentEnabled;
    this.loaderFn = config.loaderFn;
    this.platformApi = config.platformApi;
    this.warmupDataKey = config.warmupDataKey;
  }

  async load(): Promise<T> {
    if (this.isSSR && this.isWarmupDataExperimentEnabled) {
      const data = await this.loaderFn();

      this.platformApi.window.warmupData.set(this.warmupDataKey, WarmupDataHandler.encode<T>(data));

      return data;
    }

    const decodedValue = WarmupDataHandler.decode<T>(
      this.platformApi.window.warmupData.get(this.warmupDataKey),
    );

    return decodedValue ?? this.loaderFn();
  }

  static encode<T>(data: T): string {
    return btoa(escape(JSON.stringify(data)));
  }

  static decode<T>(data: string): T | undefined {
    if (!data) {
      return undefined; // Expected in Editor environments
    }

    try {
      return JSON.parse(unescape(atob(data)));
    } catch (err) {
      console.log('[WarmupDataHandler] Failed to decode', data);
      return undefined;
    }
  }
}

/** https://github.com/zloirock/core-js/blob/master/packages/core-js/modules/es.unescape.js */
function unescape(str: string) {
  const hex2 = /^[\da-f]{2}$/i;
  const hex4 = /^[\da-f]{4}$/i;

  let result = '';
  const length = str.length;
  let index = 0;
  let chr, part;

  while (index < length) {
    chr = str.charAt(index++);
    if (chr === '%') {
      if (str.charAt(index) === 'u') {
        part = str.slice(index + 1, index + 5);
        if (hex4.exec(part)) {
          result += String.fromCharCode(parseInt(part, 16));
          index += 5;
          continue;
        }
      } else {
        part = str.slice(index, index + 2);
        if (hex2.exec(part)) {
          result += String.fromCharCode(parseInt(part, 16));
          index += 2;
          continue;
        }
      }
    }
    result += chr;
  }

  return result;
}

/** https://github.com/zloirock/core-js/blob/master/packages/core-js/modules/es.escape.js */
function escape(str: string) {
  const raw = /[\w*+\-./@]/;
  const hex = function (code: number, length: number) {
    let result = code.toString(16);
    while (result.length < length) {
      result = '0' + result;
    }
    return result;
  };

  let result = '';
  const length = str.length;
  let index = 0;
  let chr, code;
  while (index < length) {
    chr = str.charAt(index++);
    if (raw.exec(chr)) {
      result += chr;
    } else {
      code = chr.charCodeAt(0);
      if (code < 256) {
        result += '%' + hex(code, 2);
      } else {
        result += '%u' + hex(code, 4).toUpperCase();
      }
    }
  }

  return result;
}
