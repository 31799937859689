import { fetchLastPostSuccess } from '../actions/fetch-last-post';

type LastPostSlugState = string;

type AnyAction = ReturnType<typeof fetchLastPostSuccess>;

export default function lastPostSlugReducer(
  state: LastPostSlugState = '',
  action: AnyAction,
): LastPostSlugState {
  switch (action.type) {
    case fetchLastPostSuccess.type:
      return action.payload.slug ?? '';

    default:
      return state;
  }
}
