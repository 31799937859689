import { createAction } from '@wix/communities-blog-client-common';

export const FETCH_CURRENT_USER_SUCCESS = 'currentUser/FETCH_SUCCESS';

const fetchCurrentUserRequest = createAction('currentUser/FETCH_REQUEST');
const fetchCurrentUserSuccess = createAction(FETCH_CURRENT_USER_SUCCESS);
const fetchCurrentUserFailure = createAction('currentUser/FETCH_FAILURE');

export const fetchCurrentUser =
  (instance) =>
  (dispatch, getState, { request }) => {
    dispatch(fetchCurrentUserRequest());
    const promise = request(`/_api/users/current`, { instance });

    return promise
      .then((currentUser) => dispatch(fetchCurrentUserSuccess(currentUser)))
      .catch(() => dispatch(fetchCurrentUserFailure()))
      .then(() => promise);
  };
