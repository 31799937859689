import { UrlMappingsKeys } from '@wix/url-mapper-utils';
import type { PlatformApi } from '../../../common/controller/platform-api';
import { encodeURIComponentIfNeeded } from '../../../common/services/uri';

export const customRouteHandler = async (platformApi: PlatformApi) => {
  const path = `/${platformApi.location.path.map(encodeURIComponentIfNeeded).join('/')}`;
  const { key, segments } = (await platformApi?.site?.getCustomizedUrlSegments?.(path)) || {};

  if (!key) {
    return null;
  }

  const slug = encodeURIComponentIfNeeded(segments?.slug);
  const instance = encodeURIComponentIfNeeded(segments?.instance);

  return (
    {
      [UrlMappingsKeys.BLOG_POST]: `/${slug}`,
      [UrlMappingsKeys.BLOG_POST_EDIT]: `/${slug}/edit`,
      [UrlMappingsKeys.BLOG_POST_PREVIEW]: `/${slug}/preview/${instance}`,
    }[key] || null
  );
};
