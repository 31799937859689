import { SET_USER } from './set-user';

export default (state = false, { type }) => {
  switch (type) {
    case SET_USER:
      return true;
    default:
      return state;
  }
};
