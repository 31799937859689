import { createAction } from '@wix/communities-blog-client-common';

export const FETCH_TAG_POSTS_REQUEST = 'tagPosts/FETCH_REQUEST';
export const FETCH_TAG_POSTS_SUCCESS = 'tagPosts/FETCH_SUCCESS';
export const FETCH_TAG_POSTS_FAILURE = 'tagPosts/FETCH_FAILURE';

export const fetchTagPostsRequest = createAction(FETCH_TAG_POSTS_REQUEST);
export const fetchTagPostsSuccess = createAction(
  FETCH_TAG_POSTS_SUCCESS,
  (payload, meta) => payload,
  (payload, meta) => meta,
);
export const fetchTagPostsFailure = createAction(
  FETCH_TAG_POSTS_FAILURE,
  (payload, meta) => payload,
  (payload, meta) => meta,
);
