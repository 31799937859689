import { createAction } from '@wix/communities-blog-client-common';

export const DELETE_POST_SUCCESS = 'post/DELETE_SUCCESS';
export const DELETE_POST_FAILURE = 'post/DELETE_FAILURE';

export const deletePostRequest = createAction('post/DELETE_REQUEST');
export const deletePostSuccess = createAction(DELETE_POST_SUCCESS);
export const deletePostFailure = createAction(DELETE_POST_FAILURE);

export function deletePost(postId, skipSuccess) {
  return (dispatch, getState, { request }) => {
    dispatch(deletePostRequest(postId));
    const promise = request.delete(`/_api/posts/${postId}`);

    return promise
      .then(() => (skipSuccess ? undefined : dispatch(deletePostSuccess(postId))))
      .catch(() => dispatch(deletePostFailure(postId)))
      .then(() => promise);
  };
}
