import { ADD_ERROR_STATE, ADD_DEBUG_STATE } from './debug-state-actions';

export function debugState(state = { errors: [], debugState: [] }, action) {
  switch (action.type) {
    case ADD_ERROR_STATE:
      return { ...state, errors: [...state.errors, action.payload] };
    case ADD_DEBUG_STATE:
      return { ...state, debugState: [...state.debugState, action.payload] };
    default:
      return state;
  }
}
