import { combineReducers, applyMiddleware, type Store, createStore } from 'redux';
import thunk from 'redux-thunk';
import { type PlatformApi } from '@app/external/common/controller/platform-api';
import { type ControllerConfig, type FlowAPI } from '@app/external/common/types';
import commonReducers from '../../common/reducers';
import createRequests from '../../common/services/create-requests';
import archiveWidgetReducer from '../aggregated-archive/reducer';
import { type ArchiveThunkArgs } from '../types';

export function createReduxStore({
  appParams,
  platformApi,
  compId,
  fedopsLogger,
  bundleName,
  flowAPI,
}: {
  appParams: ControllerConfig['appParams'];
  platformApi: PlatformApi;
  compId: ControllerConfig['compId'];
  fedopsLogger: FlowAPI['fedops'];
  bundleName: string;
  flowAPI: FlowAPI;
}) {
  const p: {
    store?: Store;
  } = {};

  const { request, aggregatorRequest } = createRequests({
    platformApi,
    bundleName,
    getStore: () => p.store!,
    flowAPI,
  });

  const middleware = [
    thunk.withExtraArgument({
      request,
      compId,
      appParams,
      fedopsLogger,
      httpClient: flowAPI.httpClient,
      flowAPI,
      platformApi,
      aggregatorRequest,
    } satisfies ArchiveThunkArgs),
  ];

  const reducers = combineReducers({
    ...commonReducers,
    archiveWidget: archiveWidgetReducer,
  });

  const initialState = { experiments: flowAPI.essentials.experiments.all() };

  p.store = createStore(reducers, initialState, applyMiddleware(...middleware));

  return p.store;
}
