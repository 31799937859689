import {
  BLOG_POST_PAGE_SECTION_ID,
  BLOG_SECTION_ID,
  BLOG_APP_ID,
} from '@wix/communities-universal/dist/src/constants/appsConfig';
import type { PlatformApi } from '../controller/platform-api';

const getSectionUrl = (sectionId: string) => async (platformApi: PlatformApi) => {
  const result = await platformApi.site.getSectionUrl({
    sectionId,
    appDefinitionId: BLOG_APP_ID,
  });

  return result.url || '';
};

export const getBlogFeedSectionUrl = getSectionUrl(BLOG_SECTION_ID);
export const getBlogPostPageSectionUrl = getSectionUrl(BLOG_POST_PAGE_SECTION_ID);
