import { chain, castArray, identity } from 'lodash';
import { OPEN_MODAL } from './modal-actions';

export const createModalOpenedMiddleware =
  ({ modalDataLoaderByType }) =>
  (store) => {
    return (nextMiddleware) => (action) => {
      const next = () => nextMiddleware(action);

      if (action.type === OPEN_MODAL) {
        const {
          payload: { type, props },
        } = action;

        const onShow = modalDataLoaderByType[type];
        if (onShow && props) {
          chain(castArray(onShow(props)))
            .filter(identity)
            .forEach(store.dispatch)
            .value();
        }
      }

      return next();
    };
  };
