import {
  ROUTE_ARCHIVE,
  ROUTE_CATEGORIES,
  ROUTE_CATEGORY,
  ROUTE_HASHTAG,
  ROUTE_HOME,
  ROUTE_TAGS,
} from '../../feed-page/constants/routes';
import {
  ROUTE_POST,
  ROUTE_PREVIEW_POST,
  ROUTE_POST_ADVANCED_SLUG,
  ROUTE_LAST_POST,
  ROUTE_EDIT_COMMENT,
  ROUTE_EDIT_COMMENT_ADVANCED_SLUG,
} from '../../post-page/constants/routes';

/** Checks if the route uses frontend adapter to fetch data (translations, posts, tags, etc.) */
export const isFeedAdapterEnabled = (route: string) => {
  const routesUsingAdapter = [
    ROUTE_CATEGORY,
    ROUTE_CATEGORIES,
    ROUTE_HOME,
    ROUTE_TAGS,
    ROUTE_HASHTAG,
    ROUTE_ARCHIVE,
  ];

  return routesUsingAdapter.includes(route);
};

export const isPostPageAdapterEnabled = (route: string) => {
  const routesUsingAdapter = [
    ROUTE_POST,
    ROUTE_PREVIEW_POST,
    ROUTE_POST_ADVANCED_SLUG,
    ROUTE_LAST_POST,
    ROUTE_EDIT_COMMENT,
    ROUTE_EDIT_COMMENT_ADVANCED_SLUG,
  ];

  return routesUsingAdapter.includes(route);
};
