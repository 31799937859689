import type { AppStore } from '../../common/types';
import { fetchRelatedPostsRenderModel } from './widget-data';

export class RelatedPostsApi {
  constructor(private readonly store: AppStore) {}

  set resourceId(postId: string | undefined) {
    this.store.dispatch(fetchRelatedPostsRenderModel(postId));
  }
}

export type IRelatedPostsApi = {
  [key in keyof RelatedPostsApi]: RelatedPostsApi[key];
};
