import { type NormalizedPost } from '@app/external/common/types';
import { fetchRelatedPostsSuccess } from '../actions/fetch-related-posts';

type RelatedPostsIdsState = Record<string, NormalizedPost[]>;

type AnyAction = ReturnType<typeof fetchRelatedPostsSuccess>;

export default function relatedPostsIdsReducer(
  state: RelatedPostsIdsState = {},
  action: AnyAction,
): RelatedPostsIdsState {
  switch (action.type) {
    case fetchRelatedPostsSuccess.type:
      return { ...state, [action.meta.postId]: action.payload };
    default:
      return state;
  }
}
