import { createBlocksModel } from '@wix/yoshi-flow-editor/blocks';

export default createBlocksModel({
  widgetName: 'Post Page',
  props: {},
  methods: {
    getPost: {
      params: [],
    },
  },
  events: {},
});
