import { merge } from 'lodash';
import { SET_APP_SETTINGS } from './app-settings-actions';

const getUpdatedAt = () => new Date().getTime();

export const appSettings = (state = {}, action = {}) => {
  switch (action.type) {
    case SET_APP_SETTINGS:
      return merge({}, state, action.payload, { updatedAt: getUpdatedAt() });
    default:
      return state;
  }
};
