import { STATUS_CONFLICT, POST_STATUS } from '@wix/communities-blog-client-common';
import { deletePost } from '../actions/delete-post';
import { deletePostPermanently } from '../actions/delete-post-permanently';
import { navigateWithinBlog } from '../actions/navigate-within-blog';
import {
  DRAFT_DELETE,
  DRAFT_MOVE_TO_TRASH,
  POST_DELETE,
  POST_DELETE_ANOTHER_WRITER_EDITING_ERROR,
  POST_MOVE_TO_TRASH,
} from '../components/message/message-types';
import { getRoute } from '../router/router-selectors';
import { isInPostPage } from './detect-route';

const getPostDeleteSuccessMessage = (postStatus, permanently) =>
  !permanently
    ? postStatus === POST_STATUS.unpublished
      ? DRAFT_MOVE_TO_TRASH
      : POST_MOVE_TO_TRASH
    : postStatus === POST_STATUS.unpublished
    ? DRAFT_DELETE
    : POST_DELETE;

const handlePostDeleteError = ({ response, showMessage }) => {
  if (response.status === STATUS_CONFLICT) {
    showMessage(POST_DELETE_ANOTHER_WRITER_EDITING_ERROR);
  }
};

const getNavigatePathname = (state) => (isInPostPage(getRoute(state)) ? '/' : undefined);

export const handlePostDelete =
  ({ permanently }) =>
  ({ postId, postStatus }, { showMessage }) =>
  (dispatch, getState) => {
    const message = getPostDeleteSuccessMessage(postStatus, permanently);
    const navigateTo = getNavigatePathname(getState());
    const action = permanently
      ? deletePostPermanently(postId, navigateTo)
      : deletePost(postId, navigateTo);

    return dispatch(action)
      .then(() => {
        if (navigateTo) {
          dispatch(navigateWithinBlog(navigateTo));
        }
        showMessage(message);
      })
      .catch((response) => handlePostDeleteError({ response, showMessage }));
  };
