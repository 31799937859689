import { findIndex } from 'lodash';
import { type PlatformApi } from '../controller/platform-api';

export const METATAG_NAME_ROBOTS = 'robots';
export const METATAG_CONTENT_NOINDEX = 'noindex';

export const setMetaTagRobotsNoIndex = (platformApi: PlatformApi) => {
  const metaTags = platformApi.seo.metaTags || [];
  const robotsIndex = findIndex(metaTags, (tag) =>
    tag.name ? tag.name === METATAG_NAME_ROBOTS : false,
  );
  if (robotsIndex > -1) {
    metaTags[robotsIndex].content = METATAG_CONTENT_NOINDEX;
  } else {
    metaTags.push({
      name: METATAG_NAME_ROBOTS,
      content: METATAG_CONTENT_NOINDEX,
      property: '',
    });
  }
  platformApi.seo.setMetaTags && platformApi.seo.setMetaTags(metaTags);
};
