import { FETCH_WRITERS_SUCCESS } from './writers-actions';

const writers = (state = [], { type, payload } = {}) => {
  switch (type) {
    case FETCH_WRITERS_SUCCESS:
      return payload;

    default:
      return state;
  }
};

export default writers;
