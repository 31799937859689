import { SET_POST_COUNT } from './set-posts-count';

const postCount = (state = 0, { type, payload } = {}) => {
  switch (type) {
    case SET_POST_COUNT:
      return payload;
    default:
      return state;
  }
};

export default postCount;
