import model from './model';

export default model.createController(({ $bind, $widget, flowAPI, $w }) => {
  return {
    pageReady: async () => {},
    exports: {
      setWriter: (newWriter: string) => {
        $w('#name').text = newWriter;
      },
    },
  };
});
